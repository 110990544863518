import React from 'react';
import { createContext, useState } from "react";
import { Z_STREAM_ERROR } from 'zlib';
import { apiUrl } from '../lib/api';

type AuthCtx = {
    token?: string,
    username?: string,
    error?: string,
    // setToken: (t?: string) => void,
    // setUsername: (u?: string) => void,
    tryLogin: (u: string, p: string, c: string) => void,
    logout: () => void,
}

const defaultAuth: AuthCtx = {
    token: localStorage.getItem('diagtoken') || undefined,
    username: undefined,
    // setToken: (t) => { },
    tryLogin: (u, p) => { },
    logout: () => { },
}

export const AuthContext = createContext(defaultAuth);

type AuthProviderProps = {
    tokenUrl?: string,
    children: any,
}

export default function AuthProvider({ tokenUrl = apiUrl('token'), children }: AuthProviderProps) {
    const [error, setError] = useState<string | undefined>(undefined);
    const [token, _setToken] = useState<string | undefined>(defaultAuth.token);

    function setToken(t: string | undefined) {
        if (t) {
            localStorage.setItem('diagtoken', t);
        }
        else {
            localStorage.removeItem('diagtoken');
        }

        _setToken(t);
    }

    const tryLogin: AuthCtx['tryLogin'] = (username, password, code) => {
        fetch(tokenUrl, {
            method: 'POST',
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                app_id: 'd7f35e5e-9f4e-4952-b860-16f9617b0d66',
                username,
                password,
                'otp_token': code,
            }),
        })
            .then(res => res.json())
            .then(data => {
                if (data.token) {
                    setToken(data.token);
                    setError(undefined);
                }
                else {
                    setToken(undefined);
                    setError(data.detail)
                }
            })
            .catch(err => {
                setError(`Login failed: ${err}`);
                setToken(undefined);
            })
    }

    const logout: AuthCtx['logout'] = () => {
        setToken(undefined)
        setError(undefined);
    }

    return (
        <AuthContext.Provider value={{
            token,
            error,
            // setToken,
            tryLogin,
            logout,
        }}>
            {children}
        </AuthContext.Provider>
    )
}