import React, { useContext, useState } from 'react';
import { debounce } from 'debounce';
import { Popup } from 'react-mapbox-gl';
import { FitBounds } from 'react-mapbox-gl/lib/map';
import { bufferedLocationBounds, LocationDescriptionForPopup, LocationsLayer } from './lib/locations';
import useCheckbox from './lib/useCheckbox';
import Mapbox, { BottomLeftOverlay } from './Mapbox';
import TripGeojsonLayer from './TripGeojsonLayer';
import { FileDrop, FileDropProps } from 'react-file-drop';
import { css } from 'emotion';
import { DateContext } from './ContextualDate';
import BoundsDisplaylayer from './lib/BoundsDisplayLayer';

type Props = {
}

type DecoratedRoute = {
    bounds?: any
    locations?: any[]
    summaryLocations?: any[]
    geojson?: any[]
}

export default function StandaloneRouteViewer(props: Props) {
    const [fileContents, setFileContents] = useState<DecoratedRoute>();
    const [bufferedBounds, setBufferedBounds] = useState<FitBounds>();
    const [selectedLocation, setSelectedLocation] = useState<any>();

    // Our mapbox layer will call our click handlers on the layers first,
    // then the overall Mapbox.onClick. This will cause the popup to be
    // immediately hidden :-( so we have to debounce selectLocation with
    // immediate=True (aka "trigger on leading edge")
    const selectLocation = debounce(setSelectedLocation, 100, true)
    const { setRelativeTo } = useContext(DateContext)

    const handleDrop: FileDropProps['onDrop'] = (files, ev) => {
        files?.item(0)?.text().then(text => {
            const details = JSON.parse(text);
            const { locations, summaryLocations } = details;
            const locs = locations || summaryLocations
            if (locs) {
                setBufferedBounds(bufferedLocationBounds(locs))
                console.log('locs', locs);
                setRelativeTo(locs[0].date)
                console.log('setting relative to', locs[0].date)
                for (let loc of locs) {
                    if (loc.source == 1 || loc.source == 2) {
                        setRelativeTo(loc.date)
                        console.log('setting relative to', locs[0].date)
                        break
                    }
                }
            }
            setFileContents(details)
        })
    }
    const { bounds, locations, summaryLocations, geojson } = fileContents ?? {};
    const [SummaryCheckbox, showSummary] = useCheckbox({ enabled: !!summaryLocations });
    const [LocationsCheckbox, showFullLocations] = useCheckbox({ enabled: !!locations, startChecked: false });
    const [GeojsonCheckbox, showGeojson] = useCheckbox({ enabled: !!geojson });

    const dropThing = (
        <FileDrop className={css`border: 1px solid grey;`} onDrop={handleDrop}>drop file here</FileDrop>
    )

    if (fileContents) {
        return (
            <>
                {dropThing}
                <Mapbox
                    style="mapbox://styles/mapbox/streets-v9"
                    fitBounds={bufferedBounds || undefined}
                    onClick={() => selectLocation(null)}
                    containerStyle={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                    }}>
                    {bufferedBounds && <BoundsDisplaylayer bounds={bufferedBounds} />}
                    <LocationsLayer
                        locations={showSummary && summaryLocations || []}
                        onClickLocation={selectLocation} />
                    <LocationsLayer
                        locations={showFullLocations && locations || []}
                        onClickLocation={selectLocation} />
                    {showGeojson ? (<TripGeojsonLayer geojson={geojson} />) : undefined}
                    {selectedLocation && (
                        <Popup coordinates={[selectedLocation.longitude, selectedLocation.latitude]}>
                            <LocationDescriptionForPopup location={selectedLocation} />
                        </Popup>
                    )}
                    <BottomLeftOverlay>
                        <SummaryCheckbox>😎 summary locations ({summaryLocations?.length || "none"})</SummaryCheckbox>
                        <LocationsCheckbox>🤖 full locations ({locations?.length || "none"})</LocationsCheckbox>
                        <GeojsonCheckbox>📲 in-app display</GeojsonCheckbox>
                    </BottomLeftOverlay>
                </Mapbox>
            </>
        );
    } else {
        return dropThing;
    }
}