import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer } from "react-relay";
import { environment } from "./lib/createRelayEnvironment";
import TripGeojsonLayer from './TripGeojsonLayer';
import { TripGeojsonQRQuery, TripGeojsonQRQueryResponse } from './__generated__/TripGeojsonQRQuery.graphql';

export default function TripGeojsonQR({ nodeId }: { nodeId: String }) {
    return (
        <QueryRenderer
            environment={environment}
            query={graphql`
                query TripGeojsonQRQuery($nodeId: ID!) {
                    node(id: $nodeId) {
                        ... on TripNode {
                            routeData {
                                freshTripGeojson
                            }
                        }
                    }
                }
            `}
            variables={{ nodeId: nodeId }}
            render={({ error, props }: { error: any, props: any }) => {
                if (error) {
                    return <div>{error.message}</div>;
                }
                else if (props) {
                    return <TripGeojsonLayer geojson={props.node?.routeData?.freshTripGeojson} />
                }
                return <div>Loading</div>;
            }}
        />
    )
}