import React from 'react';
import { useContext } from "react";
import { AuthContext } from "./AuthProvider";

export function IfAuthenticated(props: { children: any }) {
    const auth = useContext(AuthContext);
    if (auth.token) {
        return props.children;
    }
    return null;
}
export function IfNotAuthenticated(props: { children: any }) {
    const auth = useContext(AuthContext);
    if (!auth.token) {
        return props.children;
    }
    return null;
}

export function DisplayAuthenticationError() {
    const auth = useContext(AuthContext)
    if (auth.error) {
        return <span>Auth error: {auth.error}</span>
    }
    return null;
}