import { debug } from 'console';
import { accessToken } from 'mapbox-gl';
import React, { useContext, useRef, useState } from 'react';
import ReactMapboxGl, { Feature, Layer, Source } from 'react-mapbox-gl'
import ReactMapboxFactory from 'react-mapbox-gl/lib/map';
import { BottomLeftOverlay, mapboxAccessToken } from './Mapbox';

type Props = {

}

type TileJSON = {
    tiles: string,
    tileSize: number,
    maxzoom: number,
    minzoom: number,
    bounds: [number, number, number, number],
}

type HeatmapInfo = {
    expires: string,
    heatmaps: Record<string, TileJSON>,
}

const Mapbox = ReactMapboxGl({
    accessToken: mapboxAccessToken,
    hash: true,
})

// function newId() {
//     return (newId._N++).toString()
// }
// newId._N = 1


function PersonalHeatmapSource({ label, tileJson: { tiles, tileSize, maxzoom, minzoom, bounds } }: { label: string, tileJson: TileJSON }) {
    const sourceName = `heatmap_${label}`
    console.log(sourceName, tiles, maxzoom, minzoom, bounds);
    return (
        <Source id={sourceName} tileJsonSource={{
            "type": "raster",
            "tiles": tiles,
            "tileSize": tileSize ?? 256,
            "maxzoom": maxzoom,
            "minzoom": minzoom,
            "bounds": bounds,
        }} ></Source>
    );
}

function PersonalHeatmapLayer({ label, tileJson: { minzoom, bounds } }: { label: string, tileJson: TileJSON }) {
    const sourceName = `heatmap_${label}`
    const [west, south, east, north] = bounds;

    // TODO add bounding box feature
    return (
        <>
            <Layer
                type="raster"
                id={sourceName}
                source={sourceName}
                paint={{
                    'raster-opacity': 0.7,
                }}
                minZoom={minzoom - 1} // we can do one less because mbgl uses different zoom scale
            ></Layer>
            <Layer type="line" layout={{
            }} paint={{
                "line-color": "#ffffff",
                "line-width": 5,
                "line-opacity": 0.15,
                "line-dasharray": [3, 1],
            }}>
                <Feature coordinates={[
                    [west, south],
                    [west, north],
                    [east, north],
                    [east, south],
                    [west, south],
                ]} />
            </Layer>
        </>

    )
}

export default function StandaloneHeatmapViewer(props: Props) {
    const oldHmInfo = localStorage.getItem("standaloneHeatmapViewerInfo");

    const [selectedMap, setSelectedMap] = useState<string>("overall");
    const [hmInfo, setHmInfo] = useState<string>();
    const infoInput = useRef<HTMLTextAreaElement>(null);

    if (!hmInfo) {
        return (
            <form onSubmit={(ev) => infoInput.current && setHmInfo(infoInput.current.value)}>
                <label>
                    Enter info json:
                    <textarea ref={infoInput} defaultValue={oldHmInfo ?? ""} style={{
                        width: "70%",
                        height: 400
                    }}></textarea>
                </label>
                <button type="submit">Submit</button>
            </form>
        );
    }
    let parsedInfo: HeatmapInfo;
    try {
        parsedInfo = JSON.parse(hmInfo);
    }
    catch (e) {
        return <p>Error parsing json: {e}</p>
    }

    localStorage.setItem("standaloneHeatmapViewerInfo", hmInfo);

    return (
        // @ts-ignore
        <Mapbox
            style="mapbox://styles/lovetoride/cl0lm06i6001j15ogdo6z3v4s"
            onStyleData={(map, evt) => { console.log(map, evt); }}
            containerStyle={{
                position: 'absolute',
                height: "100%",
                width: "100%",
            }}>
            {Object.entries(parsedInfo.heatmaps).map(([label, tileJson]) => (
                <PersonalHeatmapSource label={label} tileJson={tileJson}></PersonalHeatmapSource>
            ))}
            <PersonalHeatmapLayer key={selectedMap} label={selectedMap} tileJson={parsedInfo.heatmaps[selectedMap]}></PersonalHeatmapLayer>

            {/* {Object.entries(parsedInfo.heatmaps).map(([label, tileJson]) => (
                <PersonalHeatmapLayer key={label} label={label} tileJson={tileJson}></PersonalHeatmapLayer>
            ))} */}
            <BottomLeftOverlay>
                Expires: {parsedInfo.expires} <br></br>
                {Object.keys(parsedInfo.heatmaps).map(label => {
                    if (label == selectedMap) {
                        return <p><label>showing <b>{label}</b></label></p>
                    }
                    else {
                        return <p><button onClick={() => setSelectedMap(label)}>Show {label} instead</button></p>
                    }
                })}
            </BottomLeftOverlay>
        </Mapbox>
    )
}