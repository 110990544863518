import { css } from 'emotion';
import React, { useContext } from 'react';
import { useState } from "react"
import { AuthContext } from './AuthProvider';

type LoginFormProps = {
    doLogin: (u: string, p: string, c: string) => void,
    defaultUsername?: string,
    defaultPassword?: string,
}

export function LoginForm({ doLogin, defaultPassword, defaultUsername }: LoginFormProps) {
    const [username, setUsername] = useState(defaultUsername || '');
    const [password, setPassword] = useState(defaultPassword || '');
    const [code, setCode] = useState('');
    return (
        <form className="Login">
            <input
                type="text"
                value={username}
                onChange={(ev) => setUsername(ev.target.value)}
                placeholder="🚲  Username" />
            <input
                type="password"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                placeholder="🤫  Password"
            />
            <input type="number" step="1"
                onChange={(ev) => setCode(ev.target.value)}
                placeholder="🗝  MFA code" />
            <button type="submit" onClick={(ev) => {
                ev.preventDefault()
                doLogin(username, password, code)
            }}>
                Login
            </button>
        </form>
    )
}

export default function Login() {
    const auth = useContext(AuthContext);
    return (
        <div className={css`margin: 1em;`}>
            <LoginForm doLogin={auth.tryLogin} />
            <p>
                Need a code? <a href="https://app-api.lovetoride.net/account/two_factor">Set up MFA over here</a>
            </p>
        </div>
    )
}