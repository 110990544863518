import React from 'react';
import './App.css';
import { IntlProvider } from 'react-intl';
import AppUserQueryRenderer from './AppUserQueryRenderer';
import AuthProvider from './auth/AuthProvider';
import { IfAuthenticated, IfNotAuthenticated, DisplayAuthenticationError } from './auth/Conditionals';
import Login from './auth/Login';
import { BrowserRouter, Route, Link } from 'react-router-dom'
import PrivacyAuthLinkMaker from './PrivacyAuthLinkMaker';
import { QueryParamProvider } from 'use-query-params';
import StandaloneRouteViewer from './StandaloneRouteViewer';
import { DateContextProvider } from './ContextualDate';
import StandaloneHeatmapViewer from './StandaloneHeatmapViewer';
import MetricsViewer from './MetricsViewer';


function AppUserRoute({ match }: { match: any }) {
  return <AppUserQueryRenderer auIntercomId={match.params.auIntercomId} />
}

function MetricsViewerRoute({ match }: { match: any }) {
  return <MetricsViewer reportId={match.params.uuid} />
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <IntlProvider locale="en-US">
            <AuthProvider>
              <IfAuthenticated>
                <Route path="/au/:auIntercomId" component={AppUserRoute} />
                <Route path="/au/:auIntercomId/r/:routeUUID" component={AppUserRoute} />
                <Route path="/metrics/:uuid" component={MetricsViewerRoute} />

              </IfAuthenticated>
              <IfNotAuthenticated>
                <Route path="/login">
                  <DisplayAuthenticationError />
                  <Login />
                </Route>
                <Route path="/au">
                  <DisplayAuthenticationError />
                  <Login />
                </Route>
                <Route path="/metrics">
                  <DisplayAuthenticationError />
                  <Login />
                </Route>
              </IfNotAuthenticated>
              <Route path="/" exact={true}>
                <ul>
                  <li>
                    User data: To see diagnostic data, you need to follow a link from Intercom.
                  </li>
                  <li>Request Privacy Authorization:<Link to="/makeauthlink">Make Auth Link</Link></li>
                </ul>
              </Route>
              <Route path="/makeauthlink">
                <PrivacyAuthLinkMaker />
              </Route>
            </AuthProvider>
            <Route path="/standalonerouteviewer">
              <DateContextProvider>
                <StandaloneRouteViewer />
              </DateContextProvider>
            </Route>
            <Route path="/heatmapviewer">
              <StandaloneHeatmapViewer />
            </Route>
          </IntlProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </div>
  );
}
// route for log viewing, alone
// route for route viewing, alone
// put them in separate windows and allow communication about what to highlight via postMessage
export default App;
