import React from 'react';
import moment, { Moment } from 'moment';

type AppDataAuthorizationMoment = {
    historyStart?: Moment,
    historyEnd?: Moment,
    notValidBefore?: Moment,
    notValidAfter?: Moment,
}

function generateUrl(adam: AppDataAuthorizationMoment) {
    let entries: string[][] = [];
    for (const [k, v] of Object.entries(adam)) {
        const vStr = v?.toISOString()
        if (k && vStr) {
            entries.push([k, vStr])
        }
    }
    let params = new URLSearchParams(entries)
    return `ltrapp://data-authorization/create?${params.toString()}`
}

type Props = {

}

export default function PrivacyAuthLinkMaker({ }: Props) {
    return (
        <div>
            <h2>Request Authorization to view Private Data</h2>
            <h3>Basic</h3>
            <ul>
                <li>
                    <a href={generateUrl({
                        historyEnd: moment(),
                        historyStart: moment().subtract(7, 'days'),
                        notValidBefore: moment(),
                        notValidAfter: moment().add(7, 'days')
                    })}>Last 7 days, valid for 7 days</a>
                </li>
                <li>
                    <a href={generateUrl({
                        historyEnd: moment(),
                        historyStart: moment().subtract(7, 'days'),
                        notValidBefore: moment(),
                        notValidAfter: moment().add(14, 'days')
                    })}>Last 7 days, valid for 14 days</a>
                </li>
                <li>
                    <a href={generateUrl({
                        historyEnd: moment().add(30, 'days'),
                        historyStart: moment().subtract(30, 'days'),
                        notValidBefore: moment(),
                        notValidAfter: moment().add(90, 'days')
                    })}>Last 30 days, plus next 30 days, valid for 90 days</a>
                </li>
            </ul>
        </div>
    )
}