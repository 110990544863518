import React, { useState, useEffect, useContext } from 'react';
import { css, cx } from 'emotion';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from "react-relay"

import { LogViewer_log } from './__generated__/LogViewer_log.graphql'
import { logRoles } from '@testing-library/react';
import LogList from './LogList';
import PrivacyAuthorizationList from './PrivacyAuthorizationList';
import { LazyLog } from 'react-lazylog';
import { ContextRelativeFormattedDate, DateContext } from './ContextualDate';
import { highlightUUIDs } from './logParsing/highlight';
import useFetch from 'use-http';
import { match } from 'assert';
import moment, { Moment } from 'moment';

export type LogViewerProps = {
    log: LogViewer_log,
    className?: string,
}

export type LogViewerState = {
    logId?: string
}

function LogDescription({ log }: { log: LogViewer_log }) {
    return (
        <>
            Log file uploaded {log.firstSeen}, <ContextRelativeFormattedDate date={log.createdOnDevice} />{' - '}
            <ContextRelativeFormattedDate date={log.modifiedOnDevice} />
        </>
    )
}

const logContainer = css`
    width: 100%;
    min-height: 500px;
    height: 100%;
`
let iso8601AtStart = /^(\d\d\d\d-\d\d-\d\d)T(\d\d:\d\d:\d\d(\.\d+)?)(Z|[-+][\d:]+)?/

function formatLinePart(part: string) {
    // split into time & message
    // highlight uuid
    // find time at start of part; 
    // console.log(part);
    let result = iso8601AtStart.exec(part);
    if (result) {
        let matchedDate = result[0];
        let remainder = part.slice(matchedDate.length);
        return (
            <>
                <span className={css`background: rgba(255, 231, 18, 0.18);`} title={matchedDate}>
                    <ContextRelativeFormattedDate date={matchedDate} />
                </span>
                <span className="logMessage" data-linePartDate={matchedDate}>
                    {highlightUUIDs(remainder)}
                </span>
            </>
    
        );
    }
    return part
}

type LineDateIndex = Map<Moment, number>;

export const ENCODED_NEWLINE = 10; // \n
export const ENCODED_CARRIAGE_RETURN = 13; // \r

const isNewline = (current: string) =>
  current === '\n' || current === '\r';

function createLineDateIndex(text: string): LineDateIndex {
    // parse a log file `text` and generate a map of Moment date -> lowest line
    // number where that date appears
    let retval = new Map<Moment, number>();
    let lastNewlineIndex = 0; // actually index of character after last newline
    let index = 0;
    let length = text.length;
    let lineNumber = 1;
    while (index < length) {
        const current = text[index];
        const next = text[index + 1];

        if (isNewline(current)) {
            const line = text.substring(lastNewlineIndex, index);
            let result = iso8601AtStart.exec(line);
            if (result) {
                let matchedDate = moment.parseZone(result[0]);
                // set earliest line number for this date string
                if (!retval.has(matchedDate)) {
                    retval.set(matchedDate, lineNumber);
                }
            }
            lineNumber += 1;
            lastNewlineIndex =
                current === '\r' && next === '\n'
                    ? index + 2
                    : index + 1;

            index = lastNewlineIndex;
        } else {
            index += 1;
        }
    }
    console.log('created index', retval)
    return retval;
}

function getLineNumberForMoment(index: LineDateIndex, targetDate: Moment): number {
    let previousLine = 0;
    for (let [d, line] of index) {
        if (targetDate.diff(d) < 0) {
            return previousLine;
        }
        previousLine = line;
    }
    return previousLine;
}


export function LogViewer({ log, className }: LogViewerProps) {
    const [lineDates, updateLineDates] = useState<LineDateIndex | null>(null);
    const [lineNumber, updateLineNumber] = useState<number>(0);
    const { loading, error, data = ''} = useFetch(log.authorizedLogLinesUrl, {
        headers: {
            'Authorization': `DiagToken ${localStorage.getItem('diagtoken')}`
        },
        responseType: 'text',
        interceptors: {
            response: async ({ response }: { response: any }) => {
                updateLineDates(createLineDateIndex(response.data))
                return response;
            }
        }
    }, [log.authorizedLogLinesUrl])

    const { relativeTo } = useContext(DateContext);
    const relativeMoment = moment.parseZone(relativeTo);

    const forceScrollToCurrentDate = () => {
        if (lineDates && relativeMoment) {
            const newLine = getLineNumberForMoment(lineDates, relativeMoment);
            if (lineNumber == newLine) {
                // we have to change to zero, wait for a re-render, then change
                // it back
                updateLineNumber(0);
                setImmediate(() => updateLineNumber(newLine));
            }
            else {
                updateLineNumber(newLine);
            }
        }

    }

    return (
        <div className={cx(className, logContainer)}>
            {!error ? (
                <>
                    {lineDates !== null && relativeMoment &&
                        <div>
                            <button onClick={forceScrollToCurrentDate}>Scroll to reference time</button>
                        </div>
                    }
                    <span className={css`position: absolute; color: white;`}><LogDescription log={log} /></span>
                    <LazyLog 
                        formatPart={formatLinePart}
                        enableSearch={true} 
                        text={data || '...'}
                        // scrollToAlignment={'center'}
                        scrollToLine={lineNumber}
                        extraLines={1}
                        // url={log.authorizedLogLinesUrl} 
                        selectableLines={true} />
                </>
            ) :
                (
                    <div>Something's gone wrong! {error.toString()} 🐞</div>
                )
            }
        </div>
    );
}

export default createFragmentContainer(LogViewer, {
    log: graphql`
        fragment LogViewer_log on AppLogNode {
            id
            firstSeen,
            createdOnDevice
            modifiedOnDevice
            sha256Hexdigest
            authorizedLogLinesUrl
        }
    `
})