import React from 'react';
import { emojiForActivity } from './lib/activityType';
import { FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';
import FormattedDuration from 'react-intl-formatted-duration';
import { css } from 'emotion';
import moment from 'moment';
import TripGeojsonLayer from './TripGeojsonLayer';
import { start } from 'repl';
import { TripList_trip } from './__generated__/TripList_trip.graphql';
import { TripViewer_trip } from './__generated__/TripViewer_trip.graphql';


function roundMiles(m: number) {
    return Math.round(m * 10) / 10
}

type Trip = TripList_trip | TripViewer_trip

function TripActivityDescription({ trip }: { trip: Trip }) {
    const tripActivityType = trip.enteredActivityType ?? trip.computedActivityType;
    if (trip.computedActivityType == tripActivityType) {
        return (
            <span className="TripActivityDescription">
                {emojiForActivity(tripActivityType)}
            </span>
        )
    }
    else {
        return (
            <span className="TripActivityDescription">
                <span className={css`
                    display: inline-block;
                    border: red 3px solid;
                    padding: 1px 5px 1px 2px;
                    background-color: rgba(255, 0, 0, 0.5);
                `}>{emojiForActivity(trip.computedActivityType)}</span>
                &nbsp;
                <span className={css`
                    display: inline-block;
                    padding: 1px 5px 1px 2px;
                    border: green 3px solid;
                `}>{emojiForActivity(tripActivityType)}</span>
            </span>
        )
    }
}

function _miles(meters: number | null) {
    return meters ? meters / 1609.344 : -1
}

export default function TripDescription({ trip }: { trip: Trip }) {
    let durationSeconds = moment.parseZone(trip.localEndDate).diff(moment.parseZone(trip.localStartDate)) / 1000
    const lengthMiles = _miles(trip.computedLengthMeters);
    let deletedMarker = trip.isDeleted ? (
        <span className={css`font-weight: bold; color: red;`}>
            [deleted]
        </span>
    ) : '';
    let excludedMarker = trip.isExcluded ? (
        <span className={css`font-weight: bold; color: orange;`}>
            [excluded]
        </span>
    ) : '';
    return (<>
        <span>
            <TripActivityDescription trip={trip} />&nbsp;&nbsp;
            <FormattedDate value={trip.localStartDate!} /> <FormattedTime value={trip.localStartDate!} />{" - "}
            <FormattedNumber value={roundMiles(lengthMiles)} style="unit" unit='mile' unitDisplay='narrow' />{" in "}
            <FormattedDuration seconds={durationSeconds} format="{hours} {minutes}" />
            {trip.isExcluded && (
                <span>excluded {trip.excludedReason}</span>
            )}
            {deletedMarker}
            {excludedMarker}
        </span>
    </>)
}
