import React from 'react';
import * as turf from '@turf/turf';
import { FitBounds } from 'react-mapbox-gl/lib/map';
import { Layer, Feature, Source } from 'react-mapbox-gl';
import { css } from 'emotion';
import { ContextRelativeFormattedDate } from '../ContextualDate';
import { ACTIVE_GPS, LAST_ROUTE_ARRIVAL, LocationSource, PASSIVE, VISIT_ARRIVAL, VISIT_DEPARTURE } from '../LocationSource';
import objectId from './objectId';
export type Location = {
    readonly latitude: number,
    readonly longitude: number,
    readonly date: string,
    readonly course: number,
    readonly source: number,
}

function locationPointFeature(location: Location) {
    return turf.point([location.longitude, location.latitude])
}

export function bufferedLocationBounds(locations: Location[], miles: number = 0.25): FitBounds {
    const boundRect = turf.envelope(turf.featureCollection(locations.map(loc => locationPointFeature(loc))));
    // bbox -> minx, miny, maxx, maxy
    const [minx, miny, maxx, maxy] = turf.bbox(turf.buffer(boundRect, miles, { units: 'miles' }));
    // fitbounds -> sw, ne order
    console.log([minx, miny, maxx, maxy])
    return [[minx, miny], [maxx, maxy]];
}

export function locationsFeatureCollection(locations: Location[]) {
    return turf.featureCollection(locations.map(loc => (turf.point([loc.longitude, loc.latitude], {
        ...loc
    }))))
}

/**
 * Get degrees per meter at a reference location
 * 
 * @param location Reference location
 */
function getDegreesPerMeterAtLocation(location: Location) {
    let degreesNearby = 0.0001
    let origin = turf.point([location.longitude, location.latitude]);
    let nearby = turf.point([location.longitude + degreesNearby, location.latitude]);
    let meters = turf.distance(origin, nearby, { units: 'meters' })
    return degreesNearby / meters;
}

function mapConstantSizeExpression(baseSize: number | Array<any>) {
    // from https://gis.stackexchange.com/questions/259407/style-line-width-proportionally-to-maintain-relative-size-in-mapbox-gl
    let baseZoom = 0;
    return [
        "interpolate", ["exponential", 2],
        ['zoom'],
        0, ['*', baseSize, Math.pow(2, 0 - baseZoom)],
        24, ['*', baseSize, Math.pow(2, 24 - baseZoom)],
    ]
}

/**
 * Convert a measurement in meters to a map expression which should render at
 * that size across all zoom levels.
 *
 * @param degreesPerMeter Decimal degrees per meter of map space (depends on
 * latitude)
 * @param meters Number of meters (or mbgl expression for meters)
 */
function mapMetersExpression(degreesPerMeter: number, meters: number | Array<any>) {
    return mapConstantSizeExpression(['*', meters, degreesPerMeter])
}

function onMouseEnter(ev: any) {
    ev.map.getCanvas().style.cursor = 'pointer'
}

function onMouseLeave(ev: any) {
    ev.map.getCanvas().style.cursor = ''
}

export function LocationsLayer({ locations, onClickLocation }: { locations: Location[], onClickLocation: (l: Location) => void }) {
    if (locations.length == 0) {
        return (<></>)
    }
    const startLocation = locations[0]
    const endLocation = locations[locations.length - 1];
    const degreesPerMeter = getDegreesPerMeterAtLocation(locations[0]);
    const maxRenderedHAcc = 60;
    const maxSolidLineHAcc = 200;
    const circleRadiusExpr = mapMetersExpression(degreesPerMeter, ['min', maxRenderedHAcc, ['get', 'horizontalAccuracy']]);
    return (
        <>
            {/* start and end  */}
            <Layer
                type="symbol"
                layout={{
                    'icon-image': ['get', 'maki'],
                    'text-field': ['get', 'text'],
                    'text-ignore-placement': true,
                    'text-allow-overlap': true,
                    'text-offset': [0, -1],
                    'icon-size': 2, // unit of original icon size
                    'icon-offset': [2, 2],
                }}
                paint={{
                    'icon-opacity': 0.7,
                }}>
                <Feature coordinates={[startLocation.longitude, startLocation.latitude]} properties={{ 'maki': 'rocket-15', 'text': 'first' }} />
                <Feature coordinates={[endLocation.longitude, endLocation.latitude]} properties={{ 'maki': 'harbor-15', 'text': 'last' }} />
            </Layer>

            {/* blurred */}
            <Layer
                type="circle"
                paint={{
                    'circle-color': ['match',
                        ['get', 'source'],
                        LAST_ROUTE_ARRIVAL, 'orange',
                        VISIT_DEPARTURE, 'green',
                        VISIT_ARRIVAL, 'red',
                        PASSIVE, 'cyan',
                        ACTIVE_GPS, 'blue',
                        'grey',
                    ],
                    'circle-radius': circleRadiusExpr,
                    'circle-blur': ['case',
                        ['>', ['get', 'horizontalAccuracy'], maxRenderedHAcc], 0.4,
                        0],
                    'circle-opacity': 0.3,
                }}>
                {(locations.map(loc => (
                    <Feature key={objectId(loc)} coordinates={[loc.longitude, loc.latitude]} properties={loc} />
                )))}
            </Layer>

            {/* outer border */}
            <Layer
                type="circle"
                paint={{
                    'circle-opacity': 0,
                    'circle-radius': circleRadiusExpr,
                    'circle-stroke-opacity': 0.7,
                    'circle-stroke-width': 0.5,
                    'circle-stroke-color': ['case',
                        ['>', ['get', 'horizontalAccuracy'], maxRenderedHAcc], 'red',
                        ['>', ['get', 'horizontalAccuracy'], maxSolidLineHAcc], 'grey',
                        'black'],
                }}>
                {(locations.map(loc => (
                    <Feature key={objectId(loc)} coordinates={[loc.longitude, loc.latitude]} properties={loc} />
                )))}
            </Layer>

            {/* center point */}
            <Layer
                type="circle"
                paint={{
                    'circle-opacity': 0.7,
                    'circle-radius': 3,
                    'circle-color': ['case',
                        ['>', ['get', 'horizontalAccuracy'], maxRenderedHAcc], 'red',
                        ['>', ['get', 'horizontalAccuracy'], maxSolidLineHAcc], 'grey',
                        'black'],
                }}
            >
                {(locations.map(loc => (
                    <Feature
                        key={objectId(loc)}
                        coordinates={[loc.longitude, loc.latitude]}
                        properties={loc}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        onClick={() => onClickLocation(loc)} />
                )))}
            </Layer>

            {/* type markers */}
            <Layer
                type="symbol"
                layout={{
                    'icon-image': ['match',
                        ['get', 'source'],
                        LAST_ROUTE_ARRIVAL, 'parking-garage-15',
                        VISIT_DEPARTURE, 'pitch-15',
                        VISIT_ARRIVAL, 'beer-15',
                        '',
                    ],
                    'icon-allow-overlap': true,
                    'icon-offset': [-8, -8],
                    // 'icon-size': 0.5,
                }}
                paint={{
                    'icon-opacity': 0.7,
                }}
            >
                {(locations.filter(loc => loc.source > PASSIVE).map(loc => (
                    <Feature
                        key={objectId(loc)}
                        coordinates={[loc.longitude, loc.latitude]}
                        properties={loc}
                    />
                )))}
            </Layer>

            {/* directional arrow */}
            <Layer
                type="symbol"
                layout={{
                    'icon-image': 'triangle-11',
                    'icon-allow-overlap': true,
                    'icon-offset': [0, -7], // up 7 px before rotation
                    // 'icon-size': 0.5,
                    'icon-rotate': ['get', 'course'],
                }}
                paint={{
                    'icon-opacity': 0.7,
                }}
            >
                {(locations.filter(loc => loc.course >= 0).map(loc => (
                    <Feature
                        key={objectId(loc)}
                        coordinates={[loc.longitude, loc.latitude]}
                        properties={loc}
                    />
                )))}
            </Layer>


            {/* connecting lines */}
            {/* <Layer
                type="line"
                paint={
                    ''
                }>

            </Layer> */}
        </>
    )
}

const locationAttrHandlers = new Map<string, (v: any) => any>([
    ['date', function LocationDate(d: string) {
        return <ContextRelativeFormattedDate date={d} />
    }],
    ['source', (s: number) => (
        <LocationSource sourceId={s} />
    )]
]);

function displayLocationAttr(key: string, value: string | number) {
    const handler = locationAttrHandlers.get(key);
    return handler ? handler(value) : value;
}

export function LocationDescriptionForPopup({ location }: { location: Location }) {
    return (
        <table>
            <tbody>
                {Object.entries(location).map(([key, value]) => (
                    <tr>
                        <td className={css`font-weight: bold`}>{key}</td>
                        <td className={css`font-family: monospace`}>{displayLocationAttr(key, value)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}