import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createPaginationContainer, RelayProp, RelayPaginationProp } from "react-relay"
import { PrivacyAuthorizationList_appuser } from './__generated__/PrivacyAuthorizationList_appuser.graphql'
import { FormattedTime, FormattedDate, FormattedRelativeTime } from 'react-intl';
import FormattedDuration from 'react-intl-formatted-duration';
import moment from 'moment';
import { css } from 'emotion';

function PrivacyAuthorizationItem({ auth }: { auth: any }) {
    let historySeconds = moment.parseZone(auth.historyEnd).diff(moment.parseZone(auth.historyStart)) / 1000
    let notValidAfter = moment.parseZone(auth.notValidAfter);
    let validInFuture = notValidAfter.diff(moment()) > 0;
    let invalidClassName = css`
        text-decoration: line-through;
        color: red;
    `;
    let validClassName = css`
        color: grey;
    `
    return (
        <li>
            <span className={validInFuture ? validClassName : invalidClassName}>
                <FormattedDate value={auth.historyStart} /> - <FormattedDate value={auth.historyEnd} />
                (<FormattedDuration seconds={historySeconds} format="{days} {hours}" />)
            </span>
            {validInFuture ? " -- expires " : " -- expired "}
            {notValidAfter.fromNow()}
        </li>
    )
}

type PrivacyAuthorizationListProps = {
    appuser: PrivacyAuthorizationList_appuser,
    relay: RelayPaginationProp,
}

class PrivacyAuthorizationList extends React.Component<PrivacyAuthorizationListProps> {

    constructor(props: PrivacyAuthorizationListProps) {
        super(props);
        let appuser = props.appuser;
    }

    render() {
        const { appuser, relay } = this.props;
        if (appuser.privacyAuthorizations?.edges.length) {
            return (
                <div>
                    <ul>
                        {appuser.privacyAuthorizations?.edges.map(
                            (edge: any) => (
                                <PrivacyAuthorizationItem key={edge.node.id} auth={edge.node} />
                            )
                        )}
                    </ul>
                    {
                        relay.hasMore() && (
                            <button onClick={() => relay.loadMore(5)}>load more</button>
                        )
                    }
                </div >
            )
        }
        else {
            return (
                <div>
                    No privacy authorizations, past or present 🙈
                </div>
            )
        }
    }
}

export default createPaginationContainer(PrivacyAuthorizationList, {
    appuser: graphql`
        fragment PrivacyAuthorizationList_appuser on AppUserNode 
            @argumentDefinitions(
                count: { type: "Int", defaultValue: 10 }
                cursor: { type: "String" }
            ) {
            privacyAuthorizations(first: $count, after: $cursor) 
            @connection(key: "PrivacyAuthorizationList_privacyAuthorizations")
            {
                edges {
                    node {
                        id,
                        historyStart,
                        historyEnd,
                        notValidAfter
                    }
                }
            }
            id
        }
        `
},
    {
        direction: 'forward',
        getVariables(props, { count, cursor }, fragmentVariables) {
            return {
                count,
                cursor,
                appuserId: props.appuser.id,
            }
        },
        // query for loadMore()
        query: graphql`
            query PrivacyAuthorizationListQuery(
                $count: Int!
                $cursor: String,
                $appuserId: ID!
            ) {
                appuser: node(id: $appuserId) {
                    ...PrivacyAuthorizationList_appuser @arguments(count: $count, cursor: $cursor)
                }
            }

        `
    }
)