import React, { useCallback, useContext } from 'react';
import { AuthContext } from './AuthProvider';

export default function UserControl() {
    const auth = useContext(AuthContext);
    if (auth.token) {
        return (
            <div>
                Logged in -- <button onClick={auth.logout}>Log out</button>
            </div>
        )
    }
    else {
        return (
            <div>Not logged in -- anonymous</div>
        )
    }
}