import React from 'react';

let uuidRe = /[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}/g
export function highlightUUIDs(text: string) {
    let highlighter = (match: RegExpExecArray) => {
        return (
            <span className="uuid">
                {match[0]}
            </span>
        )
    }
    return Array.from(iterPartsByRegexWithTransform(uuidRe, text, highlighter));
}

function _isRegExpExecArray(item: any): item is RegExpExecArray {
    return item.index !== undefined && item.length !== undefined && item.length >= 1;
}

function* iterPartsByRegex(regex: RegExp, text: string) {
    if (!regex.flags.includes('g')) {
        throw new Error("Can only be used with global regex");
    }
    let lastIndex = regex.lastIndex = 0;
    let result: RegExpExecArray | null;
    while (result = regex.exec(text)) {
        yield text.slice(lastIndex, result.index);
        yield result
        lastIndex = regex.lastIndex
    }
    yield text.slice(lastIndex)
}

function* iterPartsByRegexWithTransform(regex: RegExp, text: string, transform: (match: RegExpExecArray) => any) {
    for (let part of iterPartsByRegex(uuidRe, text)) {
        if (typeof part == 'string') {
            yield part
        }
        else if (_isRegExpExecArray(part)) {
            yield transform(part)
        }
    }

}