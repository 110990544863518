/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TripProblemReportRevisedActivityType = "A_0" | "A_1" | "A_10" | "A_102" | "A_11" | "A_12" | "A_13" | "A_14" | "A_15" | "A_16" | "A_17" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "A_999" | "%future added value";
export type TripReportsDetail_problemReport = {
    readonly id: string;
    readonly problems: ReadonlyArray<number>;
    readonly notes: string;
    readonly revisedActivityType: TripProblemReportRevisedActivityType | null;
    readonly firstSeen: string;
    readonly " $refType": "TripReportsDetail_problemReport";
};
export type TripReportsDetail_problemReport$data = TripReportsDetail_problemReport;
export type TripReportsDetail_problemReport$key = {
    readonly " $data"?: TripReportsDetail_problemReport$data;
    readonly " $fragmentRefs": FragmentRefs<"TripReportsDetail_problemReport">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TripReportsDetail_problemReport",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problems",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revisedActivityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstSeen",
      "storageKey": null
    }
  ],
  "type": "TripProblemReportNode",
  "abstractKey": null
};
(node as any).hash = '60bf097fd2f6ab8606e2a6ea81365302';
export default node;
