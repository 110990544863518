/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LogListPaginationQueryVariables = {
    count: number;
    cursor?: string | null;
    appuserId: string;
};
export type LogListPaginationQueryResponse = {
    readonly appuser: {
        readonly " $fragmentRefs": FragmentRefs<"LogList_appuser">;
    } | null;
};
export type LogListPaginationQuery = {
    readonly response: LogListPaginationQueryResponse;
    readonly variables: LogListPaginationQueryVariables;
};



/*
query LogListPaginationQuery(
  $count: Int!
  $cursor: String
  $appuserId: ID!
) {
  appuser: node(id: $appuserId) {
    __typename
    ...LogList_appuser_1G22uz
    id
  }
}

fragment LogList_appuser_1G22uz on AppUserNode {
  id
  logs(first: $count, after: $cursor) {
    edges {
      node {
        id
        createdOnDevice
        modifiedOnDevice
        ...LogViewer_log
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment LogViewer_log on AppLogNode {
  id
  firstSeen
  createdOnDevice
  modifiedOnDevice
  sha256Hexdigest
  authorizedLogLinesUrl
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appuserId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "appuserId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LogListPaginationQuery",
    "selections": [
      {
        "alias": "appuser",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "LogList_appuser"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LogListPaginationQuery",
    "selections": [
      {
        "alias": "appuser",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "AUAppLogConnection",
                "kind": "LinkedField",
                "name": "logs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AppLogNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AppLogNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdOnDevice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "modifiedOnDevice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstSeen",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sha256Hexdigest",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "authorizedLogLinesUrl",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "LogList_logs",
                "kind": "LinkedHandle",
                "name": "logs"
              }
            ],
            "type": "AppUserNode",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f4f592acd736e4f327ce96ca83557a02",
    "id": null,
    "metadata": {},
    "name": "LogListPaginationQuery",
    "operationKind": "query",
    "text": "query LogListPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $appuserId: ID!\n) {\n  appuser: node(id: $appuserId) {\n    __typename\n    ...LogList_appuser_1G22uz\n    id\n  }\n}\n\nfragment LogList_appuser_1G22uz on AppUserNode {\n  id\n  logs(first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        createdOnDevice\n        modifiedOnDevice\n        ...LogViewer_log\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment LogViewer_log on AppLogNode {\n  id\n  firstSeen\n  createdOnDevice\n  modifiedOnDevice\n  sha256Hexdigest\n  authorizedLogLinesUrl\n}\n"
  }
};
})();
(node as any).hash = '39b82644a16c7e5a724c35151e31f58d';
export default node;
