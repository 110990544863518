/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LogList_appuser = {
    readonly id: string;
    readonly logs: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly createdOnDevice: string | null;
                readonly modifiedOnDevice: string | null;
                readonly " $fragmentRefs": FragmentRefs<"LogViewer_log">;
            };
            readonly cursor: string;
        }>;
    } | null;
    readonly " $refType": "LogList_appuser";
};
export type LogList_appuser$data = LogList_appuser;
export type LogList_appuser$key = {
    readonly " $data"?: LogList_appuser$data;
    readonly " $fragmentRefs": FragmentRefs<"LogList_appuser">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "logs"
        ]
      }
    ]
  },
  "name": "LogList_appuser",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "logs",
      "args": null,
      "concreteType": "AUAppLogConnection",
      "kind": "LinkedField",
      "name": "__LogList_logs_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AppLogNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AppLogNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdOnDevice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "modifiedOnDevice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "LogViewer_log"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AppUserNode",
  "abstractKey": null
};
})();
(node as any).hash = '211bd07fa210f8056d7b39209b8d211e';
export default node;
