/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PrivacyAuthorizationList_appuser = {
    readonly privacyAuthorizations: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly historyStart: string;
                readonly historyEnd: string;
                readonly notValidAfter: string;
            };
        }>;
    } | null;
    readonly id: string;
    readonly " $refType": "PrivacyAuthorizationList_appuser";
};
export type PrivacyAuthorizationList_appuser$data = PrivacyAuthorizationList_appuser;
export type PrivacyAuthorizationList_appuser$key = {
    readonly " $data"?: PrivacyAuthorizationList_appuser$data;
    readonly " $fragmentRefs": FragmentRefs<"PrivacyAuthorizationList_appuser">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "privacyAuthorizations"
        ]
      }
    ]
  },
  "name": "PrivacyAuthorizationList_appuser",
  "selections": [
    {
      "alias": "privacyAuthorizations",
      "args": null,
      "concreteType": "AUAppDataPrivacyAuthorizationConnection",
      "kind": "LinkedField",
      "name": "__PrivacyAuthorizationList_privacyAuthorizations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AppDataPrivacyAuthorizationNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AppDataPrivacyAuthorizationNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "historyStart",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "historyEnd",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "notValidAfter",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "AppUserNode",
  "abstractKey": null
};
})();
(node as any).hash = '41692f6d922a7f15da0025182fc9df0b';
export default node;
