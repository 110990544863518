import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { TripViewer_trip } from './__generated__/TripViewer_trip.graphql';


export default function TripDetails({ trip }: { trip: TripViewer_trip }) {
    return (<>
        <div>
            <FormattedDate value={trip.localStartDate ?? undefined} /> at <FormattedTime value={trip.localStartDate ?? undefined} />
            {" -- "}
            <FormattedDate value={trip.localEndDate ?? undefined} /> at <FormattedTime value={trip.localEndDate ?? undefined} />
        </div>
        <div>
            Start: {trip.computedStartLocationDescription}
        </div>
        <div>
            End: {trip.computedEndLocationDescription}
        </div>
    </>)
}