import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

/**
 * Get original parameter from querystring, using use-query-params
 * 
 * The result will not change over the lifetime of the page; will be `null` if not present
 * 
 * @param name name of key in query parameters, like "?key=value&otherkey=othervalue"
 * @returns Original value of query parameter; `null` if not present
 */
export function useOriginalQueryParamString(name) {
    const [value, setValue] = useQueryParam(name, StringParam)
    const [original, setOriginal] = useState<string | null | undefined>(undefined)
    useEffect(() => {
        if (original === undefined) {
            console.log(`setting original ${name} = ${value}`)
            setOriginal(value ?? null)
        }
    }, [value])

    return original
}