import { TripList_trip } from "../__generated__/TripList_trip.graphql";

type ActivityType = TripList_trip['computedActivityType']

const emojiLookup = new Map<ActivityType, string>([
    ["A_1", "🏃🏾‍♀️"],
    ["A_2", "🚴🏼‍♀️"],
    ["A_3", "🚗"],
    ["A_4", "🚶🏽‍♂️"],
    ["A_5", "🚌"],
    ["A_6", "🚈"],
    ["A_7", "🛌"],
    ["A_102", "🤳"],
])

export function emojiForActivity(activityType: ActivityType) {
    let result = emojiLookup.get(activityType);
    return result || "⁉️"
}