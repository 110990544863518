import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer } from "react-relay"
import { environment } from './lib/createRelayEnvironment';
import AppUserOverview from './AppUserOverview';
import { useOriginalQueryParamString } from './lib/originalQueryParam';

export default function AppUserQueryRenderer({ auIntercomId }: { auIntercomId: string }) {
    const originalTripUUID = useOriginalQueryParamString('t');

    return (
        <QueryRenderer
            environment={environment}
            query={graphql`
                query AppUserQueryRendererQuery($auIntercomId: UUID!, $aroundTripUUID: String) {
                    # The root field for the query
                    appuserByIntercomId(intercomId: $auIntercomId) {
                    # A reference to your fragments named in children
                    ...AppUserOverview_appuser @arguments(aroundTripUUID: $aroundTripUUID),
                    }
                }
            `}
            variables={{ auIntercomId, aroundTripUUID: originalTripUUID }}
            render={({ error, props }: { error: any, props: any }) => {
                if (error) {
                    if (error.message.includes('AppTrip matching query does not exist')) {
                        return <div>Error: This trip could not be found; it might have been deleted.</div>;
                    }

                    return <div>Error: {error.message}</div>;
                } else if (props) {
                    console.log('got props in AppUserQR', props);
                    return <AppUserOverview appuser={props.appuserByIntercomId} />;
                }
                return <div>Loading</div>;
            }}
        />
    );
}
