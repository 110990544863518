/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AppUserOverview_appuser = {
    readonly id: string;
    readonly deleted: boolean;
    readonly intercomExternalUserId: string;
    readonly isInternalUser: boolean;
    readonly firstLogs: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"LogViewer_log">;
            };
        }>;
    } | null;
    readonly firstTrips: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"TripViewer_trip">;
            };
        }>;
    } | null;
    readonly ltruser: {
        readonly email: string;
    } | null;
    readonly latestDevice: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"IosDeviceViewer_iosDevice">;
            };
        }>;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"LogList_appuser" | "PrivacyAuthorizationList_appuser" | "TripList_appuser">;
    readonly " $refType": "AppUserOverview_appuser";
};
export type AppUserOverview_appuser$data = AppUserOverview_appuser;
export type AppUserOverview_appuser$key = {
    readonly " $data"?: AppUserOverview_appuser$data;
    readonly " $fragmentRefs": FragmentRefs<"AppUserOverview_appuser">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aroundTripUUID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppUserOverview_appuser",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "intercomExternalUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInternalUser",
      "storageKey": null
    },
    {
      "alias": "firstLogs",
      "args": (v1/*: any*/),
      "concreteType": "AUAppLogConnection",
      "kind": "LinkedField",
      "name": "logs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AppLogNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AppLogNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "LogViewer_log"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "logs(first:1)"
    },
    {
      "alias": "firstTrips",
      "args": (v1/*: any*/),
      "concreteType": "AUAppTripConnection",
      "kind": "LinkedField",
      "name": "apptrips",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TripNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TripNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TripViewer_trip"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "apptrips(first:1)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LtrUserNode",
      "kind": "LinkedField",
      "name": "ltruser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "latestDevice",
      "args": (v1/*: any*/),
      "concreteType": "AUIOSDeviceStatusConnection",
      "kind": "LinkedField",
      "name": "iosDevices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IOSDeviceStatusNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IOSDeviceStatusNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "IosDeviceViewer_iosDevice"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "iosDevices(first:1)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LogList_appuser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PrivacyAuthorizationList_appuser"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "aroundTripUUID",
          "variableName": "aroundTripUUID"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 5
        }
      ],
      "kind": "FragmentSpread",
      "name": "TripList_appuser"
    }
  ],
  "type": "AppUserNode",
  "abstractKey": null
};
})();
(node as any).hash = '8826617bc1568e6b08f131559322b40d';
export default node;
