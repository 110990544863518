/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TripReportsDetailQueryRendererQueryVariables = {
    nodeId: string;
};
export type TripReportsDetailQueryRendererQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"TripReportsDetail_trip">;
    } | null;
};
export type TripReportsDetailQueryRendererQuery = {
    readonly response: TripReportsDetailQueryRendererQueryResponse;
    readonly variables: TripReportsDetailQueryRendererQueryVariables;
};



/*
query TripReportsDetailQueryRendererQuery(
  $nodeId: ID!
) {
  node(id: $nodeId) {
    __typename
    ... on TripNode {
      ...TripReportsDetail_trip
    }
    id
  }
}

fragment TripReportsDetail_problemReport on TripProblemReportNode {
  id
  problems
  notes
  revisedActivityType
  firstSeen
}

fragment TripReportsDetail_trip on TripNode {
  id
  problemReports(first: 100) {
    edges {
      node {
        id
        ...TripReportsDetail_problemReport
      }
    }
  }
  verificationReports(first: 100) {
    edges {
      node {
        id
        ...TripReportsDetail_verificationReport
      }
    }
  }
}

fragment TripReportsDetail_verificationReport on TripVerificationReportNode {
  id
  activityType
  firstSeen
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nodeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "nodeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstSeen",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TripReportsDetailQueryRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TripReportsDetail_trip"
              }
            ],
            "type": "TripNode",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TripReportsDetailQueryRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "TripProblemReportConnection",
                "kind": "LinkedField",
                "name": "problemReports",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TripProblemReportNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TripProblemReportNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "problems",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "notes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "revisedActivityType",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "problemReports(first:100)"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "TripVerificationReportConnection",
                "kind": "LinkedField",
                "name": "verificationReports",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TripVerificationReportNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TripVerificationReportNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "activityType",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "verificationReports(first:100)"
              }
            ],
            "type": "TripNode",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5cd2b49c496f9f865934815972e3d2c",
    "id": null,
    "metadata": {},
    "name": "TripReportsDetailQueryRendererQuery",
    "operationKind": "query",
    "text": "query TripReportsDetailQueryRendererQuery(\n  $nodeId: ID!\n) {\n  node(id: $nodeId) {\n    __typename\n    ... on TripNode {\n      ...TripReportsDetail_trip\n    }\n    id\n  }\n}\n\nfragment TripReportsDetail_problemReport on TripProblemReportNode {\n  id\n  problems\n  notes\n  revisedActivityType\n  firstSeen\n}\n\nfragment TripReportsDetail_trip on TripNode {\n  id\n  problemReports(first: 100) {\n    edges {\n      node {\n        id\n        ...TripReportsDetail_problemReport\n      }\n    }\n  }\n  verificationReports(first: 100) {\n    edges {\n      node {\n        id\n        ...TripReportsDetail_verificationReport\n      }\n    }\n  }\n}\n\nfragment TripReportsDetail_verificationReport on TripVerificationReportNode {\n  id\n  activityType\n  firstSeen\n}\n"
  }
};
})();
(node as any).hash = '82c1a69244803e17532012e62de4a7a4';
export default node;
