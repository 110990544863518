import React from 'react';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import { css } from 'emotion';

export const mapboxAccessToken = "pk.eyJ1IjoibG92ZXRvcmlkZSIsImEiOiJjazl5ajB4cHcwMmZsM2ZxcjRndGYxdHZjIn0.W4nBTnD4II8RWZ7CRv7Mmw"

export const defaultMapProps = {
    style: "mapbox://styles/mapbox/streets-v9"
}

export { Layer, Feature }

export function BottomLeftOverlay(props: { children: any }) {
    return (
        <div className={css`    
            position: absolute;
            bottom: 30px;
            left: 10px;
            background: rgba(237, 253, 0, 0.75);
            padding: 5px 10px;
            overflow: auto;
            /* pointer-events: none; */
        `}>
            {props.children}
        </div>
    )
}

export function RightOverlay(props: { children: any }) {
    return (
        <div className={css`
            position: absolute;
            right: 10px;
            bottom: 30px;
            top: 10px;
            background: rgba(237, 253, 0, 0.75);
            overflow: auto;
        `}>
            {props.children}
        </div>
    )
}

export default ReactMapboxGl({
    accessToken: mapboxAccessToken,
    // hash: true,
});