/**
 * class TripProblem(IntegerChoices):
    DISTANCE = 1
    START_TIME = 2
    END_TIME = 3
    MODE = 4
    MAP = 5
    START_LOCATION = 6
    END_LOCATION = 7

 */

type TripProblem = {
    value: number,
    name: string,
}

const problemLookup = new Map<number, TripProblem>([
    { value: 1, name: 'Distance' },
    { value: 2, name: 'Start Time' },
    { value: 3, name: 'End Time' },
    { value: 4, name: 'Mode' },
    { value: 5, name: 'Map' },
    { value: 6, name: 'Start Location' },
    { value: 7, name: 'End Location' },
].map(problem => [problem.value, problem]),
);

export default function getTripProblem(value: number): TripProblem {
    return problemLookup.get(value) ?? { value: 0, name: 'UNKNOWN!' }
}