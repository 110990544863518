import React, { useState, useEffect } from 'react';
import { css } from 'emotion';

const modelNameTableUrl = 'https://gist.githubusercontent.com/adamawolf/3048717/raw/19b4cc0627ff669f1c4589324b9cb45e4948ec01/Apple_mobile_device_types.txt'

let cache = {
    modelNameTableContents: ''
};

const lineMatch = /(\S+)\s*:\s*(.+)/g

function getModelNameTableContents(): Promise<string> {
    if (cache.modelNameTableContents) {
        return Promise.resolve(cache.modelNameTableContents);
    }
    return fetch(modelNameTableUrl)
        .then(res => res.text())
        .then(text => {
            cache.modelNameTableContents = text;
            return text;
        })
}

function getModelDescriptionForModelCode(modelCode: string) {
    return getModelNameTableContents().then(text => {
        lineMatch.lastIndex = 0;
        let match;
        while ((match = lineMatch.exec(text)) !== null) {
            if (match[1] == modelCode) {
                return match[2];
            }
        }
        return null;
    })
}

export function IosDeviceName({ modelCode }: { modelCode: string }) {
    const [modelDescription, setDescription] = useState<string | null>(null)
    getModelDescriptionForModelCode(modelCode).then(setDescription);
    return (
        <span className={css`color: #555555; font-style: italic;`} title={modelCode}>
            {modelDescription || modelCode}
        </span>
    )
}