import React, { useEffect, useState } from "react";
import { createFragmentContainer, usePaginationFragment } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import { StringParam, useQueryParam } from "use-query-params";
import ConditionalLoader from "./lib/ConditionalLoader";
import { TripList_trip } from "./__generated__/TripList_trip.graphql";
import { css } from "emotion";
import TripDescription from "./TripDescription";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ClientDescription } from "./IosDeviceViewer";
import UTCOffsetDescription from "./UTCOffsetDescription";
import TripReportsDetailQueryRenderer from "./TripReportsDetailQueryRenderer";
import { TripEndNotificationButton } from "./TripEndNotificationButton";
import { emojiForActivity } from "./lib/activityType";


function TripListItem({ trip, isSelected, onClick }: { trip: TripList_trip, onClick: any, isSelected: boolean }) {
    // let tossedStyle = route.tossed ? 'text-decoration: line-through;' : '';
    // let isDeletedOrTossed = route.tossed || route.deleted;
    let isDeletedOrExcluded = trip.isDeleted || trip.isExcluded;
    var style = css`
        cursor: pointer;
        color: blue;
        &:hover {
            color: purple
        }
        font-weight: ${isSelected ? 'bold' : 'normal'};
        text-decoration: ${isDeletedOrExcluded ? 'line-through' : 'none'};
    `;
    return (
        <li key={trip.id} onClick={onClick} className={style}>
            <TripDescription trip={trip} />
            {isSelected && (
                <div className={css`font-size: smaller; font-weight: normal;`}>
                    <ul className={css`list-style: none;`}>
                        <li>UUID: <CopyToClipboard text={trip.uuid}><span>{trip.uuid}</span></CopyToClipboard></li>
                        <li>Made from {trip.routeData.routeIds?.length ?? '??'} route(s)</li>
                        <li>First seen {trip.routeData.earliestFirstSeen}</li>
                        <li>Uploaded by <ClientDescription client={trip.client} /></li>
                        <li>Uploaded in <UTCOffsetDescription utcoffset={trip.utcoffset ?? 0} /></li>
                    </ul>
                    <TripReportsDetailQueryRenderer nodeId={trip.id} />
                    <TripEndNotificationButton tripId={trip.id} />
                </div>
            )
            }
        </li >
    )
}

export const TripListItemFragment = createFragmentContainer(TripListItem, {
    trip: graphql`
        fragment TripList_trip on TripNode {
            id
            uuid
            utcoffset
            isDeleted
            isExcluded
            excludedReason
            created
            modified
            localStartDate
            localEndDate
            computedLengthMeters
            computedActivityType
            enteredActivityType
            computedMapBounds
            routeData {
                routeIds
                earliestFirstSeen
                latestFirstSeen
            }
            client {
                platform
                deviceModel
                userAgent
                version
                build
            }
        }
        `
})

function TripNodeList(
    { nodes, onClickNode, selectedNode }: {
        onClickNode?: ((node: any) => void),
        nodes: [any],
        selectedNode?: any,
    }
) {

    return (
        <div>
            {nodes?.length ? (
                <ul>
                    {nodes.map(
                        (node) => (
                            <TripListItemFragment
                                key={node.id}
                                trip={node}
                                isSelected={node.id == selectedNode?.id}
                                onClick={() => {
                                    onClickNode && onClickNode(node)
                                }} />
                        )
                    )}
                </ul>
            ) : (
                <span>No authorized trips 🗺</span>
            )}
        </div>
    )
}


export default function TripList(
    { appuser, onSelectNode }: {
        appuser: any,
        onSelectNode?: ((node: any) => void),
        // selectedNode: any,
    }
) {
    const {
        data, loadNext, loadPrevious, hasNext, hasPrevious, isLoadingNext, isLoadingPrevious, refetch
    } = usePaginationFragment(graphql`
        fragment TripList_appuser on AppUserNode 
        @argumentDefinitions(
            first: { type: "Int" }
            last: { type: "Int" }
            after: { type: "String" }
            before: { type: "String" }
            aroundTripUUID: { type: "String" }
        ) 
        @refetchable(queryName: "TripListRefetchQuery") {
            id,
            apptrips(first: $first, after: $after, last: $last, before: $before, aroundTripUUID: $aroundTripUUID) 
            @connection(key: "TripList_apptrips")
            {
                edges {
                    node {
                        id
                        uuid
                        ...TripList_trip
                        ...TripViewer_trip
                    }
                    cursor
                }
            }
        }
    `, appuser)

    const [selectedNode, setSelectedNode] = useState<any>();
    const selectNode = (node) => {
        setSelectedNode(node);
        onSelectNode && onSelectNode(node);
    }

    const [selectedTripUUID, setSelectedTripUUID] = useQueryParam('t', StringParam);
    const [selectedRouteUUID, setSelectedRouteUUID] = useQueryParam('r', StringParam);
    useEffect(() => {
        // migration from old URL style, which indexed by route id
        if (!!selectedRouteUUID) {
            setSelectedTripUUID(selectedRouteUUID);
            setSelectedRouteUUID(undefined);
        }
    }, [selectedRouteUUID])

    const nodes = data.apptrips?.edges.map(edge => edge.node);

    // select the first node if none is specified, or the specified one from the URL
    useEffect(() => {
        if (nodes?.length) {
            if (selectedTripUUID == undefined) {
                selectNode(nodes[0]);
            }
            else {
                let found = nodes.find(node => node.uuid == selectedTripUUID);
                if (found) {
                    selectNode(found)
                }
                else {
                    console.error('FIXME could not find node with uuid from url')
                    // TODO: raise an error?
                }
            }
        }

    })

    return (
        <div>
            <ConditionalLoader loading={isLoadingPrevious}>
                {hasPrevious ? (
                    <button onClick={() => loadPrevious(5)}>load later trips</button>
                ) : (
                    <em> ... beginning of trip history -- no later trips ...</em>
                )}
            </ConditionalLoader>
            <TripNodeList nodes={nodes} selectedNode={selectedNode} onClickNode={(node) => {
                setSelectedTripUUID(node.uuid);
                selectNode(node);
            }} />
            <ConditionalLoader loading={isLoadingNext}>
                {hasNext ? (
                    <button onClick={() => loadNext(5)}>load earlier trips</button>
                ) : (
                    <em> ... end of trip history -- no earlier trips ... </em>
                )}
            </ConditionalLoader>

        </div>
    )
}