/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LogViewer_log = {
    readonly id: string;
    readonly firstSeen: string;
    readonly createdOnDevice: string | null;
    readonly modifiedOnDevice: string | null;
    readonly sha256Hexdigest: string | null;
    readonly authorizedLogLinesUrl: string | null;
    readonly " $refType": "LogViewer_log";
};
export type LogViewer_log$data = LogViewer_log;
export type LogViewer_log$key = {
    readonly " $data"?: LogViewer_log$data;
    readonly " $fragmentRefs": FragmentRefs<"LogViewer_log">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LogViewer_log",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstSeen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdOnDevice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "modifiedOnDevice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sha256Hexdigest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorizedLogLinesUrl",
      "storageKey": null
    }
  ],
  "type": "AppLogNode",
  "abstractKey": null
};
(node as any).hash = '2394a6addaa723d9550c93d87f67e9b1';
export default node;
