import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { TripReportsDetail_trip } from './__generated__/TripReportsDetail_trip.graphql';
import getTripProblem from './lib/tripProblem';
import { emojiForActivity } from './lib/activityType';
import { ContextRelativeFormattedDate } from './ContextualDate';
import { TripReportsDetail_verificationReport } from './__generated__/TripReportsDetail_verificationReport.graphql';
import { TripReportsDetail_problemReport } from './__generated__/TripReportsDetail_problemReport.graphql';
import { css } from 'emotion';

type TripReportsDetailProps = {
    trip: TripReportsDetail_trip
}

const ProblemReportItem = createFragmentContainer(
    function ProblemReportItem({ problemReport }: { problemReport: TripReportsDetail_problemReport }) {
        const { problems, notes, revisedActivityType, firstSeen } = problemReport
        const problemNames = problems.map(v => getTripProblem(v).name)
        return (
            <li>
                <div><label>Submitted:</label> <ContextRelativeFormattedDate date={firstSeen} /></div>
                <div>
                    <label>Problems:</label><span>{problemNames.join(', ')}</span>
                </div>
                {revisedActivityType && (
                    <div>
                        <label>Should be:</label><span>{emojiForActivity(revisedActivityType)}</span>
                    </div>
                )}
                <div>
                    <label>Notes:</label><span>{notes}</span>
                </div>
            </li>
        )
    }, {

    problemReport: graphql`
        fragment TripReportsDetail_problemReport on TripProblemReportNode {
            id
            problems
            notes
            revisedActivityType
            firstSeen
        }
    `
})

const VerificationReportItem = createFragmentContainer(
    function VerificationReportItem({ verificationReport }: { verificationReport: TripReportsDetail_verificationReport }) {
        const { firstSeen, activityType } = verificationReport
        return (
            <li>
                verified {emojiForActivity(activityType)} <ContextRelativeFormattedDate date={firstSeen} />
            </li>
        )
    },
    {
        verificationReport: graphql`
            fragment TripReportsDetail_verificationReport on TripVerificationReportNode {
                id
                activityType
                firstSeen
            }
        `
    })

export function TripReportsDetail({ trip }: TripReportsDetailProps) {
    const problemReports = trip.problemReports?.edges.filter(e => e !== null).map(e => e!.node) ?? [];
    const verificationReports = trip.verificationReports?.edges.filter(e => e !== null).map(e => e!.node) ?? [];
    return (
        <>
            {problemReports.length > 0 && (
                <div className={css`
                `}>
                    <em className={css`
                        font-weight: bold; color: red;
                    `}>Problem Reports ({problemReports.length})</em>
                    <ul>
                        {problemReports.map(report => <ProblemReportItem problemReport={report} />)}
                    </ul>
                </div>
            )
            }
            {verificationReports.length > 0 && (
                <div className={css`
                `}>
                    <em className={css`
                        font-weight: bold; color: green;
                    `}>Verified ({verificationReports.length})</em>
                    <ul>
                        {verificationReports.map(report => <VerificationReportItem verificationReport={report} />)}
                    </ul>
                </div>
            )
            }
        </>
    )
}

export default createFragmentContainer(TripReportsDetail, {
    trip: graphql`
        fragment TripReportsDetail_trip on TripNode {
            id
            problemReports(first: 100) {
                edges {
                    node {
                        id
                        ...TripReportsDetail_problemReport
                    }
                }
            }
            verificationReports(first: 100) {
                edges {
                    node {
                        id
                        ...TripReportsDetail_verificationReport
                    }
                }
            }
        }
    `
})