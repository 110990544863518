/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClientPlatform = "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type IosClientDistributionMethod = "APPSTORE" | "DEBUG" | "TESTFLIGHT" | "UNKNOWN" | "%future added value";
export type IosDeviceViewer_iosDevice = {
    readonly id: string;
    readonly firstSeen: string;
    readonly firstSeenClient: {
        readonly userAgent: string | null;
        readonly platform: ClientPlatform | null;
        readonly version: string | null;
        readonly build: string | null;
        readonly deviceModel: string | null;
        readonly iosDistributionMethod: IosClientDistributionMethod | null;
    } | null;
    readonly lastSeen: string;
    readonly lastSeenClient: {
        readonly userAgent: string | null;
        readonly platform: ClientPlatform | null;
        readonly version: string | null;
        readonly build: string | null;
        readonly deviceModel: string | null;
        readonly iosDistributionMethod: IosClientDistributionMethod | null;
    } | null;
    readonly currentTimeZoneId: string;
    readonly currentTimeZoneOffset: number;
    readonly " $refType": "IosDeviceViewer_iosDevice";
};
export type IosDeviceViewer_iosDevice$data = IosDeviceViewer_iosDevice;
export type IosDeviceViewer_iosDevice$key = {
    readonly " $data"?: IosDeviceViewer_iosDevice$data;
    readonly " $fragmentRefs": FragmentRefs<"IosDeviceViewer_iosDevice">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userAgent",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "platform",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "build",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceModel",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "iosDistributionMethod",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IosDeviceViewer_iosDevice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstSeen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientObject",
      "kind": "LinkedField",
      "name": "firstSeenClient",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastSeen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientObject",
      "kind": "LinkedField",
      "name": "lastSeenClient",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentTimeZoneId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentTimeZoneOffset",
      "storageKey": null
    }
  ],
  "type": "IOSDeviceStatusNode",
  "abstractKey": null
};
})();
(node as any).hash = 'b982386401b8de5149630bed7346acda';
export default node;
