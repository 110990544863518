import React, { Children } from "react";
import SyncLoader from 'react-spinners/SyncLoader';

type ConditionaLoaderProps = {
    loading: boolean,
    children: React.ReactNode,
}

export default function ConditionalLoader({ loading, children }: ConditionaLoaderProps) {
    if (loading) {
        return (<SyncLoader loading={loading} />);
    }
    else {
        return <>{children}</>
    }
}