/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TripReportsDetail_trip = {
    readonly id: string;
    readonly problemReports: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"TripReportsDetail_problemReport">;
            };
        }>;
    } | null;
    readonly verificationReports: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"TripReportsDetail_verificationReport">;
            };
        }>;
    } | null;
    readonly " $refType": "TripReportsDetail_trip";
};
export type TripReportsDetail_trip$data = TripReportsDetail_trip;
export type TripReportsDetail_trip$key = {
    readonly " $data"?: TripReportsDetail_trip$data;
    readonly " $fragmentRefs": FragmentRefs<"TripReportsDetail_trip">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TripReportsDetail_trip",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "TripProblemReportConnection",
      "kind": "LinkedField",
      "name": "problemReports",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TripProblemReportNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TripProblemReportNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TripReportsDetail_problemReport"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "problemReports(first:100)"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "TripVerificationReportConnection",
      "kind": "LinkedField",
      "name": "verificationReports",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TripVerificationReportNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TripVerificationReportNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TripReportsDetail_verificationReport"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "verificationReports(first:100)"
    }
  ],
  "type": "TripNode",
  "abstractKey": null
};
})();
(node as any).hash = 'be157ae6dcd0de5351839fe8ccf41671';
export default node;
