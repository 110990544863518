/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TripVerificationReportActivityType = "A_0" | "A_1" | "A_10" | "A_102" | "A_11" | "A_12" | "A_13" | "A_14" | "A_15" | "A_16" | "A_17" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "A_999" | "%future added value";
export type TripReportsDetail_verificationReport = {
    readonly id: string;
    readonly activityType: TripVerificationReportActivityType;
    readonly firstSeen: string;
    readonly " $refType": "TripReportsDetail_verificationReport";
};
export type TripReportsDetail_verificationReport$data = TripReportsDetail_verificationReport;
export type TripReportsDetail_verificationReport$key = {
    readonly " $data"?: TripReportsDetail_verificationReport$data;
    readonly " $fragmentRefs": FragmentRefs<"TripReportsDetail_verificationReport">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TripReportsDetail_verificationReport",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstSeen",
      "storageKey": null
    }
  ],
  "type": "TripVerificationReportNode",
  "abstractKey": null
};
(node as any).hash = '7bb93ddb8a47000109043f96fa616b0c';
export default node;
