/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TripList_appuser = {
    readonly id: string;
    readonly apptrips: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly uuid: string;
                readonly " $fragmentRefs": FragmentRefs<"TripList_trip" | "TripViewer_trip">;
            };
            readonly cursor: string;
        }>;
    } | null;
    readonly " $refType": "TripList_appuser";
};
export type TripList_appuser$data = TripList_appuser;
export type TripList_appuser$key = {
    readonly " $data"?: TripList_appuser$data;
    readonly " $fragmentRefs": FragmentRefs<"TripList_appuser">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "apptrips"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aroundTripUUID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./TripListRefetchQuery.graphql.ts'),
      "identifierField": "id"
    }
  },
  "name": "TripList_appuser",
  "selections": [
    (v1/*: any*/),
    {
      "alias": "apptrips",
      "args": [
        {
          "kind": "Variable",
          "name": "aroundTripUUID",
          "variableName": "aroundTripUUID"
        }
      ],
      "concreteType": "AUAppTripConnection",
      "kind": "LinkedField",
      "name": "__TripList_apptrips_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TripNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TripNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "uuid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TripList_trip"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TripViewer_trip"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AppUserNode",
  "abstractKey": null
};
})();
(node as any).hash = 'edccfce4b1bb888240396ca4668a58e2';
export default node;
