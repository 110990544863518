import React, { useContext } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { LogList_appuser } from './__generated__/LogList_appuser.graphql'
import { LogViewer_log } from './__generated__/LogViewer_log.graphql'
import { createPaginationContainer, RelayPaginationProp } from "react-relay"
import { FormattedDate, FormattedTime } from 'react-intl';
import FormattedDuration from 'react-intl-formatted-duration';
import moment from 'moment';
import { css } from 'emotion';
import { ContextRelativeFormattedDate, DateContext } from './ContextualDate';

function logContainsDate(log: LogViewer_log, date: any) {
    if (!date) {
        return false;
    }
    date = moment.parseZone(date);
    return date > moment.parseZone(log.createdOnDevice) && date < moment.parseZone(log.modifiedOnDevice);
}

function LogListItem({ log, onClick }: { log: LogViewer_log, onClick: (log: LogViewer_log) => void }) {
    let extentSeconds = moment.parseZone(log.modifiedOnDevice).diff(moment.parseZone(log.createdOnDevice)) / 1000;
    let { relativeTo } = useContext(DateContext);
    const isRelevant = logContainsDate(log, relativeTo);
    return (
        <li key={log.id} onClick={() => onClick(log)}>
            <span className={css`
                cursor: pointer;
                color: rgb(21%, 31.9%, 100%);
                font-weight: ${isRelevant ? 'bold' : 'normal'};
                &:hover {
                    color: rgb(0%, 30.2%, 70%);
                }
            `}>
                <FormattedDate value={log.createdOnDevice || undefined} /> - <FormattedDate value={log.modifiedOnDevice || undefined} />
                (<FormattedDuration seconds={extentSeconds} format="{days} {hours} {minutes} {seconds}"></FormattedDuration>)
            </span>
        </li>
    )
}

function LogList(
    { appuser, relay, onClickNode }: {
        onClickNode?: ((node: any) => void),
        appuser: LogList_appuser,
        relay: RelayPaginationProp
    }
) {
    const edges = appuser.logs?.edges
    return (
        <div>
            {edges && edges.length ? (
                <ul>
                    {appuser.logs?.edges.map(
                        (edge: any) => (
                            <LogListItem key={edge.node.id} log={edge.node} onClick={(log) => onClickNode && onClickNode(log)} />
                        )
                    )}
                </ul>
            ) : (
                <span>No authorized logs 👻</span>
            )}
            {
                relay.hasMore() && (
                    <button onClick={() => relay.loadMore(5)}>load more</button>
                )
            }
        </div>
    )
}

export default createPaginationContainer(LogList, {
    appuser: graphql`
        fragment LogList_appuser on AppUserNode 
            @argumentDefinitions(
                count: { type: "Int", defaultValue: 10 }
                cursor: { type: "String" }
            ) {
            id,
            logs(first: $count, after: $cursor) 
            @connection(key: "LogList_logs")
            {
                edges {
                    node {
                        id
                        createdOnDevice
                        modifiedOnDevice
                        ...LogViewer_log
                    }
                    cursor
                }
            }
        }
        `
},
    {
        direction: 'forward',
        getVariables(props, { count, cursor }, fragmentVariables) {
            return {
                count,
                cursor,
                appuserId: props.appuser.id,
            }
        },
        // query for loadMore()
        query: graphql`
            query LogListPaginationQuery(
                $count: Int!
                $cursor: String,
                $appuserId: ID!
            ) {
                appuser: node(id: $appuserId) {
                    ...LogList_appuser @arguments(count: $count, cursor: $cursor)
                }
            }

        `
    }
)