/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AppTripComputedActivityType = "A_0" | "A_1" | "A_10" | "A_102" | "A_11" | "A_12" | "A_13" | "A_14" | "A_15" | "A_16" | "A_17" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "A_999" | "%future added value";
export type AppTripEnteredActivityType = "A_0" | "A_1" | "A_10" | "A_102" | "A_11" | "A_12" | "A_13" | "A_14" | "A_15" | "A_16" | "A_17" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "A_999" | "%future added value";
export type AppTripExcludedReason = "A_1" | "A_2" | "A_3" | "%future added value";
export type RouteActivityType = "A_0" | "A_1" | "A_10" | "A_102" | "A_11" | "A_12" | "A_13" | "A_14" | "A_15" | "A_16" | "A_17" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "A_999" | "%future added value";
export type TripViewer_trip = {
    readonly id: string;
    readonly uuid: string;
    readonly utcoffset: number;
    readonly isDeleted: boolean;
    readonly isExcluded: boolean;
    readonly excludedReason: AppTripExcludedReason | null;
    readonly created: string;
    readonly modified: string;
    readonly localStartDate: string | null;
    readonly localEndDate: string | null;
    readonly computedLengthMeters: number | null;
    readonly computedActivityType: AppTripComputedActivityType;
    readonly enteredActivityType: AppTripEnteredActivityType | null;
    readonly computedMapBounds: string | null;
    readonly computedStartLocationDescription: string;
    readonly computedEndLocationDescription: string;
    readonly routeData: {
        readonly preparedLocations: string | null;
        readonly rawLocations: string | null;
    };
    readonly routes: ReadonlyArray<{
        readonly creationDate: string;
        readonly closedDate: string | null;
        readonly tossed: boolean;
        readonly tossedDate: string | null;
        readonly tossedReason: string | null;
        readonly summaryLocations: string;
        readonly deleted: boolean;
        readonly activityType: RouteActivityType;
        readonly originalLengthMeters: number;
        readonly predictionAggregators: ReadonlyArray<{
            readonly aggregatePredictedActivity: {
                readonly confidence: number;
                readonly activityType: number;
            };
            readonly predictions: ReadonlyArray<{
                readonly startDate: string;
            }> | null;
            readonly " $fragmentRefs": FragmentRefs<"PredictionAggregatorsList_predictionAggregators">;
        }> | null;
    } | null> | null;
    readonly " $refType": "TripViewer_trip";
};
export type TripViewer_trip$data = TripViewer_trip;
export type TripViewer_trip$key = {
    readonly " $data"?: TripViewer_trip$data;
    readonly " $fragmentRefs": FragmentRefs<"TripViewer_trip">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activityType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TripViewer_trip",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "utcoffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExcluded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "excludedReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "modified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "computedLengthMeters",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "computedActivityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enteredActivityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "computedMapBounds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "computedStartLocationDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "computedEndLocationDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RouteDataObject",
      "kind": "LinkedField",
      "name": "routeData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preparedLocations",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rawLocations",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RouteNode",
      "kind": "LinkedField",
      "name": "routes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creationDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closedDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tossed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tossedDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tossedReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "summaryLocations",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deleted",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalLengthMeters",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PredictionAggregator",
          "kind": "LinkedField",
          "name": "predictionAggregators",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PredictedActivity",
              "kind": "LinkedField",
              "name": "aggregatePredictedActivity",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "confidence",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Prediction",
              "kind": "LinkedField",
              "name": "predictions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startDate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PredictionAggregatorsList_predictionAggregators"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TripNode",
  "abstractKey": null
};
})();
(node as any).hash = 'da32c77a9a2408730bd676e8e93cd8ee';
export default node;
