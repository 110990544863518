import React, { useState } from 'react';
import { useMutation } from 'react-relay';
import ConditionalLoader from './lib/ConditionalLoader';
import graphql from 'babel-plugin-relay/macro';
import { TripEndNotificationButtonMutation, TripEndNotificationButtonMutationResponse } from './__generated__/TripEndNotificationButtonMutation.graphql';
import { css } from 'emotion'

export function TripEndNotificationButton({ tripId }: { tripId: string }) {
    const [sendIt, isSending] = useMutation<TripEndNotificationButtonMutation>(graphql`
        mutation TripEndNotificationButtonMutation($input: ID!) {
            sendTripEndNotification(tripId: $input) {
                count
                message
              }
        }
    `);

    const [result, setResult] = useState<TripEndNotificationButtonMutationResponse['sendTripEndNotification']>()

    return (
        <ConditionalLoader loading={isSending}>
            <div>
                <button onClick={() => sendIt({ variables: { input: tripId }, onCompleted: res => setResult(res.sendTripEndNotification) })}>
                    Send end-of-trip notification
            </button>
                {result && (
                    <div>Sent to {result.count} device(s): <div className={css`font-size: larger; padding: 10px; border: brown 1px solid`}>{result.message}</div></div>
                )}
            </div>
        </ConditionalLoader>
    )
}