import React, { useState, useContext } from 'react';
import moment, { Moment, isMoment, min } from 'moment';
import { types } from 'util';
import { FormattedDate } from 'react-intl';
import { css } from 'emotion';


type Dateish = string | Moment | Date;

function _parseNewAppDateFormat(s: string) {
    // e.g. "1645124995|-25200" with utc offset or "1645124995" for utc
    let [left, right] = s.split("|");
    let m = moment.unix(Number(left));
    let offsetSeconds = Number(right);
    if (!isNaN(offsetSeconds)) {
        m.utcOffset(offsetSeconds / 60)
    }
    return m.isValid() ? m : null
}

function dateishToString(d?: Dateish) {
    console.log('got dateish', d);
    if (d === undefined || d === null) {
        return undefined
    } else if (typeof d === 'string') {
        var m = _parseNewAppDateFormat(d);
        if (m !== null) {
            return m.toISOString()
        }
        return d
    } else if (isMoment(d)) {
        return d.toISOString();
    } else if (types.isDate(d)) {
        return moment(d).toISOString()
    }
    console.log('unhandled dateish ', d);
}

function dateishToMoment(d?: Dateish) {
    if (d === undefined) {
        return d
    } else if (typeof d === 'string') {
        var result = _parseNewAppDateFormat(d);
        if (result !== null) {
            return result
        }
        result = moment.parseZone(d)
        if (result.isValid()) {
            return result
        }
        return undefined
    } else if (isMoment(d)) {
        return d;
    } else if (types.isDate(d)) {
        return moment(d)
    }
}

type DateContext_t = {
    relativeTo?: string
    focus?: string
    setRelativeTo: (d?: Dateish) => void,
    setFocus: (d?: Dateish) => void,
}

const defaultDateContext: DateContext_t = {
    relativeTo: undefined,
    focus: undefined,
    setRelativeTo: (d?: Dateish) => { },
    setFocus: (d?: Dateish) => { },
}

export const DateContext = React.createContext(defaultDateContext);

type DateContextProviderProps = {

}

type DateContextProviderState = {
    relativeTo?: string,
    focus?: string,
}

export class DateContextProvider extends React.Component<DateContextProviderProps, DateContextProviderState> {
    constructor(props: DateContextProviderProps) {
        super(props);
        this.state = {
            relativeTo: undefined,
            focus: undefined,
        }
        this.setRelativeTo = this.setRelativeTo.bind(this);
        this.setFocus = this.setFocus.bind(this);
    }

    setRelativeTo(d?: Dateish) {
        this.setState({ relativeTo: dateishToString(d) })
    }

    setFocus(d?: Dateish) {
        this.setState({ focus: dateishToString(d) })
    }

    render() {
        const { relativeTo, focus } = this.state;
        return (
            <DateContext.Provider value={{
                relativeTo,
                focus,
                setRelativeTo: this.setRelativeTo,
                setFocus: this.setFocus,
            }}>
                {this.props.children}
            </DateContext.Provider>
        )
    }
}

export function ClickToFocusDate({ date, children }: { date: Dateish, children: any }) {
    const { setFocus } = useContext(DateContext);
    return (
        <span onClick={() => setFocus(date)}>
            {children}
        </span>
    )
    // clicks on this will change the "focus" date
}

export function ContextRelativeFormattedDate({ date }: { date?: Dateish | null }) {
    const { relativeTo } = useContext(DateContext);
    if (date === null) {
        date = undefined;
    }
    if (relativeTo === undefined) {
        return <FormattedDate value={isMoment(date) ? date.toDate() : date} />
    }
    else {
        const millis = dateishToMoment(date)?.diff(moment.parseZone(relativeTo));
        if (millis) {
            const totalSeconds = millis / 1000
            return <>{formatDurationBrief(totalSeconds)}</>
        }
        return <>+??m??s</>;
    }
}

function formatDurationBrief(totalSeconds?: number) {
    if (totalSeconds) {
        const isPositive = (totalSeconds >= 0);
        const absTotal = Math.abs(totalSeconds);
        if (absTotal < 3600) {
            const minutes = (Math.floor(absTotal / 60)).toString()
            const seconds = (Math.floor(absTotal) % 60).toString().padStart(2, '0');
            return <>{isPositive ? '+' : '-'}{minutes}m{seconds}s</>;
        }
        else if (absTotal < 86400) {
            const hours = (Math.floor(absTotal / 3600)).toString()
            const minutes = (Math.floor(absTotal / 60) % 60).toString().padStart(2, '0');
            return <>{isPositive ? '+' : '-'}{hours}h{minutes}m</>;
        }
        else {
            const days = (Math.floor(absTotal / 86400)).toString()
            const hours = (Math.floor(absTotal / 3600) % 24).toString().padStart(2, '0');
            return <>{isPositive ? '+' : '-'}{days}d{hours}h</>;
        }
    }
    return '+??m??s';
}

function _parseEnteredDate(value: string) {
    let m = dateishToMoment(value);
    if (m !== undefined && m.isValid()) {
        return m
    }
    else {
        // try to parse the format from Xcode Instruments
        // e.g. "Jan 14, 2021 at 1:54:04 PM"
        let re = new RegExp(/^(.+) at (\d\d?):(\d\d?):(\d\d?) (PM|AM)$/);
        let match = re.exec(value);
        console.log("value", value, "got match", match)
        if (match !== null) {
            m = moment.parseZone(match[1]); // date

            let hours = parseInt(match[2], 10);
            if (match[5] == 'PM') {
                hours += 12
            }
            m.hours(hours);
            m.minutes(parseInt(match[3], 10));
            m.seconds(parseInt(match[4], 10));
            if (m.isValid()) {
                return m
            }
        }
    }
}

export function DateContextControl() {
    const { relativeTo, setRelativeTo } = useContext(DateContext);
    const [value, setValue] = useState<string>(dateishToString(relativeTo) || "");
    const [err, setError] = useState("");

    return (
        <>
            <form onSubmit={ev => {
                ev.preventDefault();
                let m = _parseEnteredDate(value);
                m !== undefined ? setRelativeTo(m) : setError("invalid date format");
            }}>
                Dates currently relative to: {dateishToString(relativeTo)}<br />
                <label>Set relative to: <input type="text" value={value} onChange={ev => setValue(ev.target.value)}></input></label>
                <button>Save</button>
                {!!err && <span className={css`color: red;`}>{err}</span>}
            </form>

        </>
    )
}

// TODO: add a component that has title={date} and perhaps some formatting.
// and figure out how to scroll in the LazyLog