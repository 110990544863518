import React from 'react';
import { css } from 'emotion';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from "react-relay"
import Mapbox, { BottomLeftOverlay, RightOverlay } from './Mapbox';
import { bufferedLocationBounds, Location, locationsFeatureCollection, LocationsLayer, LocationDescriptionForPopup } from './lib/locations';
import { Layer, GeoJSONLayer, Popup } from 'react-mapbox-gl';
import TripDescription from './TripDescription';
import PredictionAggregatorsList from './PredictionAggregatorsList';
import { DateContext } from './ContextualDate';
import { debounce } from 'debounce';
import TripDetails from './RouteDetails';
import TripGeojsonQR from './TripGeojsonQR';
import { TripViewer_trip } from './__generated__/TripViewer_trip.graphql';

// todo: allow setting relativeTo to creationdate or startdate
// todo: allow setting focus
// todo: show prediction nearest to a location, or show location nearest to prediction

export type TripViewerProps = {
    trip: TripViewer_trip,
}

export type TripViewerState = {
    preparedLocations: Location[],
    bufferedLocationBounds?: any
    rawLocations?: Location[],
    popupLocation?: Location,
    predictionCount?: number
    predictionRoute?: any,
    showRawLocations: boolean,
    showPreparedLocations: boolean,
    showPredictions: boolean,
    showTripGeojson: boolean,
}

function Checkbox({ disabled, value, onChange, children }: { disabled: boolean, value: boolean, onChange: () => void, children: any }) {
    return (
        <label className={disabled ? css`text-decoration: line-through;` : ''}>
            <input disabled={disabled} type="checkbox" checked={value} onChange={onChange} />
            {children}
        </label>
    )
}

class TripViewer extends React.Component<TripViewerProps, TripViewerState> {
    static contextType = DateContext

    constructor(props: TripViewerProps) {
        super(props);
        let { trip } = this.props;
        this.state = this._parseLocationsForState(trip);
    }

    _parseLocationsForState(trip: TripViewer_trip) {
        let predictionCount = 0;
        const paggs = []
        // const paggs = route.predictionAggregators
        // if (paggs?.length) {
        //     predictionCount = paggs
        //         .map(pagg => pagg?.predictions?.length)
        //         .filter(len => !!len)
        //         .reduce((count, value) => count! + value!);
        // }
        let state: TripViewerState = {
            preparedLocations: JSON.parse(trip.routeData?.preparedLocations ?? '[]'),
            rawLocations: JSON.parse(trip.routeData?.rawLocations ?? '[]'),
            predictionCount,
            showPreparedLocations: true,
            showRawLocations: false,
            showPredictions: false,
            showTripGeojson: true,
        }
        if (state.preparedLocations.length) {
            state.bufferedLocationBounds = bufferedLocationBounds(state.preparedLocations)
        }
        else {
            state.bufferedLocationBounds = null
        }
        return state
    }

    componentWillReceiveProps(nextProps: TripViewerProps) {
        if (nextProps.trip !== this.props.trip) {
            this.setState({ popupLocation: undefined })
            this.setState(this._parseLocationsForState(nextProps.trip))
            this.context.setRelativeTo(nextProps.trip.localStartDate);
        }
    }

    componentDidMount() {
        const { trip } = this.props;
        this.context.setRelativeTo(trip.localStartDate);
    }

    // componentDidUpdate() {
    //     setImmediate(() => this.context.setRelativeTo(this.props.route.creationDate))
    // }

    render() {
        let trip = this.props.trip;
        const {
            popupLocation,
            rawLocations: parsedLocations,
            preparedLocations: parsedSummaryLocations,
            predictionCount,
            showRawLocations: showFullLocations,
            showPreparedLocations: showSummaryLocations,
            showPredictions,
            showTripGeojson,
        } = this.state;
        const canShowSummary = !!(parsedSummaryLocations.length);
        const canShowFull = !!(parsedLocations?.length);
        const canShowPredictions = !!(predictionCount);

        // Our mapbox layer will call our click handlers on the layers first,
        // then the overall Mapbox.onClick. This will cause the popup to be
        // immediately hidden :-( so we have to debounce selectLocation with
        // immediate=True (aka "trigger on leading edge")
        const selectLocation = debounce((popupLocation) => this.setState({ popupLocation }), 100, true)
        console.log('render map');
        return (
            <>
                <Mapbox
                    style="mapbox://styles/mapbox/streets-v9"
                    fitBounds={this.state.bufferedLocationBounds}
                    onClick={() => selectLocation(null)}
                    containerStyle={{
                        position: 'relative',
                        height: '100%',
                        width: '100%'
                    }}>
                    <LocationsLayer
                        locations={showSummaryLocations && parsedSummaryLocations || []}
                        onClickLocation={selectLocation} />
                    <LocationsLayer
                        locations={showFullLocations && parsedLocations || []}
                        onClickLocation={selectLocation} />
                    {showTripGeojson ? (<TripGeojsonQR nodeId={trip.id} />) : undefined}
                    {popupLocation && (
                        <Popup coordinates={[popupLocation.longitude, popupLocation.latitude]}>
                            <LocationDescriptionForPopup location={popupLocation} />
                        </Popup>
                    )}
                    <BottomLeftOverlay>
                        <TripDescription trip={trip} /><br />
                        <TripDetails trip={trip} />
                        <Checkbox disabled={!canShowSummary} value={canShowSummary && showSummaryLocations} onChange={() => this.setState({ showPreparedLocations: !showSummaryLocations })}>
                            😎 prepared locations ({parsedSummaryLocations?.length || "none"})
                        </Checkbox>
                        <br />
                        <Checkbox disabled={!canShowFull} value={canShowFull && showFullLocations} onChange={() => this.setState({ showRawLocations: !showFullLocations })}>
                            🤖 raw locations ({parsedLocations?.length || "none"})
                        </Checkbox>
                        <br />
                        <Checkbox disabled={!canShowPredictions} value={canShowPredictions && showPredictions} onChange={() => this.setState({ showPredictions: !showPredictions })}>
                            🎲 predictions ({predictionCount})
                        </Checkbox>
                        <br />
                        <Checkbox disabled={false} value={showTripGeojson} onChange={() => this.setState({ showTripGeojson: !showTripGeojson })}>
                            📲 in-app display
                        </Checkbox>
                    </BottomLeftOverlay>
                </Mapbox >
            </>
        );
    }

}

export default createFragmentContainer(TripViewer, {
    trip: graphql`
        fragment TripViewer_trip on TripNode {
            id
            uuid
            utcoffset
            isDeleted
            isExcluded
            excludedReason
            created
            modified
            localStartDate
            localEndDate
            computedLengthMeters
            computedActivityType
            enteredActivityType
            computedMapBounds
            computedStartLocationDescription
            computedEndLocationDescription

            routeData {
                preparedLocations
                rawLocations
            }

            routes {
                creationDate
                closedDate
                tossed
                tossedDate
                tossedReason
                summaryLocations
                # locations
                deleted
                activityType
                originalLengthMeters

                predictionAggregators {
                    aggregatePredictedActivity {
                        confidence
                        activityType
                    }
                    predictions {
                        startDate
                    }
                    ...PredictionAggregatorsList_predictionAggregators
                }
            }
        }
    `
})