/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AppTripComputedActivityType = "A_0" | "A_1" | "A_10" | "A_102" | "A_11" | "A_12" | "A_13" | "A_14" | "A_15" | "A_16" | "A_17" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "A_999" | "%future added value";
export type AppTripEnteredActivityType = "A_0" | "A_1" | "A_10" | "A_102" | "A_11" | "A_12" | "A_13" | "A_14" | "A_15" | "A_16" | "A_17" | "A_2" | "A_3" | "A_4" | "A_5" | "A_6" | "A_7" | "A_8" | "A_9" | "A_999" | "%future added value";
export type AppTripExcludedReason = "A_1" | "A_2" | "A_3" | "%future added value";
export type ClientPlatform = "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type TripList_trip = {
    readonly id: string;
    readonly uuid: string;
    readonly utcoffset: number;
    readonly isDeleted: boolean;
    readonly isExcluded: boolean;
    readonly excludedReason: AppTripExcludedReason | null;
    readonly created: string;
    readonly modified: string;
    readonly localStartDate: string | null;
    readonly localEndDate: string | null;
    readonly computedLengthMeters: number | null;
    readonly computedActivityType: AppTripComputedActivityType;
    readonly enteredActivityType: AppTripEnteredActivityType | null;
    readonly computedMapBounds: string | null;
    readonly routeData: {
        readonly routeIds: ReadonlyArray<number | null> | null;
        readonly earliestFirstSeen: string | null;
        readonly latestFirstSeen: string | null;
    };
    readonly client: {
        readonly platform: ClientPlatform | null;
        readonly deviceModel: string | null;
        readonly userAgent: string | null;
        readonly version: string | null;
        readonly build: string | null;
    } | null;
    readonly " $refType": "TripList_trip";
};
export type TripList_trip$data = TripList_trip;
export type TripList_trip$key = {
    readonly " $data"?: TripList_trip$data;
    readonly " $fragmentRefs": FragmentRefs<"TripList_trip">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TripList_trip",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "utcoffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExcluded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "excludedReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "modified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "computedLengthMeters",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "computedActivityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enteredActivityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "computedMapBounds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RouteDataObject",
      "kind": "LinkedField",
      "name": "routeData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "routeIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "earliestFirstSeen",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestFirstSeen",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientObject",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "platform",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userAgent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "version",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "build",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TripNode",
  "abstractKey": null
};
(node as any).hash = '446b145ecc5badc202e184beddd40a04';
export default node;
