import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer } from "react-relay"
import { environment } from "./lib/createRelayEnvironment"
import TripReportsDetail from './TripReportsDetail';

type TripReportsQRProps = {
    nodeId: string, // route node id
}

export default function TripReportsDetailQueryRenderer({ nodeId }: TripReportsQRProps) {
    return (
        <QueryRenderer environment={environment}
            query={graphql`
                query TripReportsDetailQueryRendererQuery($nodeId: ID!) {
                    node(id: $nodeId) {
                        ... on TripNode {
                            ...TripReportsDetail_trip
                        }
                    }
                }
            `}
            variables={{ nodeId }}
            render={({ error, props }: { error: any, props: any }) => {
                if (error) {
                    return <span>{error.message}</span>
                }
                else if (props) {
                    const node = props.node;
                    return <TripReportsDetail trip={node} />
                }
                return <span>trip reports detail (loading?)</span>
            }}
        />
    )
}