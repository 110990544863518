import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { IosDeviceName } from './IosModelName';
import { TripList_trip } from './__generated__/TripList_trip.graphql';

type Client = TripList_trip['client']

export function ClientDescription({ client }: { client: Client }) {
    if (client?.platform == "IOS") {
        return (
            <span title={client.userAgent ?? ""}>🍎 {client.version} ({client.build})
                {client.deviceModel ?
                    <> on <IosDeviceName modelCode={client.deviceModel} /></>
                    : null}
            </span>
        );
    } else if (client?.platform === 'ANDROID') {
        return <span title={client.userAgent ?? ""}>🤖Android {client.version} ({client.build})
            {client.deviceModel ?
                <> on <IosDeviceName modelCode={client.deviceModel} /></>
                : null}
            </span>;
    }
    else {
        return <span title={client?.userAgent ?? ""}>UNKNOWN CLIENT</span>
    }
}

type IosDeviceViewerProps = {
    iosDevice: any
}

export class IosDeviceViewer extends React.Component<IosDeviceViewerProps, {}> {
    constructor(props: IosDeviceViewerProps) {
        super(props);
    }

    render() {
        if (this.props.iosDevice) {
            const { currentTimeZoneId } = this.props.iosDevice;
            const { lastSeenClient } = this.props.iosDevice
            return (
                <span><ClientDescription client={lastSeenClient} /> in {currentTimeZoneId}</span>
            )
        }
        else {
            return null;
        }
    }

}

export default createFragmentContainer(IosDeviceViewer, {
    iosDevice: graphql`
        fragment IosDeviceViewer_iosDevice on IOSDeviceStatusNode {
            id
            firstSeen
            firstSeenClient {
                userAgent
                platform
                version
                build
                deviceModel
                iosDistributionMethod
            }
            lastSeen
            lastSeenClient {
                userAgent
                platform
                version
                build
                deviceModel
                iosDistributionMethod
            }
            currentTimeZoneId
            currentTimeZoneOffset
        }
    `
})