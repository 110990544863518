import { css } from 'emotion';
import React from 'react';
import useFetch from 'use-http';
import { apiUrl } from './lib/api';
import ConditionalLoader from './lib/ConditionalLoader';

type Props = { reportId: string }
type MetricsReportData = {
    created: string,
    uuid: string,
    reportURL: string,
    jsonURL: string,
}

function PreTextFromUrl({ url }: { url: string }) {
    const { loading, error, data = '' } = useFetch(url, {
        responseType: 'text',
    }, [url])
    return (
        <ConditionalLoader loading={loading}>
            <pre>{data}</pre>
        </ConditionalLoader>
    )

}

export default function MetricsViewer({ reportId }: Props) {
    const { loading, error, data = {} } = useFetch(apiUrl(`metricsreports/${reportId}`), {
        headers: {
            'Authorization': `DiagToken ${localStorage.getItem('diagtoken')}`
        },
        responseType: 'json',
    }, [reportId])

    if (error) {
        return (<p>Error: {error.toString()}</p>);
    }

    return (
        <ConditionalLoader loading={loading}>
            <p>Created: {data.created}</p>
            <p>
                Download: <a href={data.reportURL} download>Report (text)</a> or <a href={data.jsonURL} download>raw metrics (json)</a>
            </p>
            <hr />
            <div className={css`margin: 1em;`}>
                <PreTextFromUrl url={data.reportURL} />
            </div>

        </ConditionalLoader>
    )
}