import { LngLatBoundsLike } from 'mapbox-gl';
import { LngLatBounds } from 'mapbox-gl/src/geo/lng_lat_bounds';
import React from 'react';
import { Feature, Layer, Source } from 'react-mapbox-gl';



export default function BoundsDisplaylayer({ bounds }: { bounds: [[number, number], [number, number]] }) {
    // bounds = [sw, ne]
    const [sw, ne] = bounds;
    const [w, s] = sw;
    const [e, n] = ne;

    // construct rectangle as line
    const rect_coords = [
        [w, s],
        [e, s],
        [e, n],
        [w, n],
        [w, s],
    ]
    return <>
        <Layer
            type="line"
            paint={{
                'line-width': 2,
                'line-opacity': 0.4,
                'line-color': 'black',
            }}>
            <Feature coordinates={rect_coords} />
        </Layer>
    </>
}