import { apiUrl } from './api';

const {
    Environment,
    Network,
    RecordSource,
    Store,
} = require('relay-runtime');

// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
function fetchQuery(
    operation: any,
    variables: any,
    cacheConfig: any,
    uploadables: any,
) {
    console.log('Starting graphql request: \n', operation.text);
    console.log('With variables: \n', variables);
    return fetch(apiUrl('graphql'), {
        method: 'POST',
        headers: {
            // Add authentication and other headers here
            'content-type': 'application/json',
            'authorization': `DiagToken ${localStorage.getItem('diagtoken')}`,
        },
        body: JSON.stringify({
            query: operation.text, // GraphQL text from input
            variables,
        }),
    }).then(response => {
        return response.json();
    }).then(json => {

        if (json && json.errors) {
            const ltr_error_code = json.errors[0]?.ltr_error_code
            if (ltr_error_code === 401) {
                console.log('logging out because this toke is broke');
                localStorage.removeItem('diagtoken');
            }
            throw new Error(json.errors[0]?.message || 'Unknown error returned by server');
        }
        console.log('Finished graphql request: \n', json)
        return json;
    })
}

const source = new RecordSource();
const store = new Store(source);
const network = Network.create(fetchQuery);
const handlerProvider = null;

const environment = new Environment({
    handlerProvider, // Can omit.
    network,
    store,
});

export { environment }
