import React from 'react';

// 
// /**
//  Describes the source of a Location object.

//  - unknown: Default value.
//  - activeGPS: Location received when active, (possibly) high-accuracy tracking is enabled.
//  - passive: Location received when passive, (possibly) low-accuracy tracking is enabled.
//  - geofence: Center location of a geofence created when the geofence event fires.
//  - visitArrival: Location created from a Core Location Visit Arrival event. Possibly used as a means of establishing route's end location. Timestamp is estimated but could be used for determining a route's end time.
//  - visitDeparture: Location created from a Core Location Visit Departure event. Used as a means of establishing a route's start location. Timestamp is estimated but could be used for determining a route's start time.
//  - lastRouteArrival: Location copied from the end of the last route. Used as an alternative means of establishing start location. Timestamp should always be ignored.
//  */
// @objc enum LocationSource : Int16, CustomStringConvertible {
//     case unknown = 0
//     case activeGPS // 1
//     case passive // 2
//     case geofence // 3
//     case visitArrival // 4
//     case visitDeparture // 5
//     case lastRouteArrival // 6

export const UNKNOWN = 0
export const ACTIVE_GPS = 1
export const PASSIVE = 2
export const GEOFENCE = 3
export const VISIT_ARRIVAL = 4
export const VISIT_DEPARTURE = 5
export const LAST_ROUTE_ARRIVAL = 6

const emojiForSourceId = new Map<number, string>([
    [0, '❓'],
    [1, '🏹'],
    [2, '🛌'],
    [3, '🤺'],
    [4, '🛬'],
    [5, '🛫'],
    [6, '⏱'],
])



const nameForSourceId = new Map<number, string>([
    [0, 'unkown'],
    [1, 'active gps'],
    [2, 'passive'],
    [3, 'geofence'],
    [4, 'visit arrival'],
    [5, 'visit departure'],
    [6, 'last route arrival'],
])


export function LocationSource({ sourceId }: { sourceId: number }) {
    return (
        <>
            {emojiForSourceId.get(sourceId) || '‼️'} {nameForSourceId.get(sourceId) || 'bogus source id!'}
        </>
    )
}