import { GeoJSONSource, Layer } from 'mapbox-gl';
import React from 'react';
import { Source } from 'react-mapbox-gl';
const triplayers = require('./triplayers.json')

function _firstLabelLayerId(layers: Layer[]) {
    for (let layer of layers) {
        if (layer.id.includes('label')) {
            return layer.id;
        }
    }
}

export default function TripGeojsonLayer({ geojson }: { geojson: any }) {
    if (typeof geojson == 'string') {
        geojson = JSON.parse(geojson)
    }
    return (
        <>
            <Source id="trip" geoJsonSource={{ "type": "geojson", "data": geojson }} onSourceAdded={(source: any) => {
                console.log('onSourceAdded', source);
                let map = source.map;
                let style = map.getStyle();
                let beforeId = _firstLabelLayerId(style.layers)
                for (let layer of triplayers) {
                    layer['source'] = source.id;
                    map.addLayer(layer, beforeId);
                }
            }} />

        </>
    )
}