import { css } from 'emotion';
import React, { useState } from 'react';
import { start } from 'repl';

function CheckboxHOC({ disabled, value, onChange }: { disabled: boolean, value: boolean, onChange: () => void }): React.FunctionComponent {
    const Checkbox: React.FunctionComponent = ({ children }) => {
        return (
            <label className={disabled ? css`text-decoration: line-through;` : ''}>
                <input disabled={disabled} type="checkbox" checked={value} onChange={onChange} />
                {children}
            </label>
        )
    }
    return Checkbox
}

export default function useCheckbox({ enabled, startChecked = true }: { enabled: boolean, startChecked?: boolean }): [React.FunctionComponent, boolean] {
    const [isChecked, setChecked] = useState<boolean>(startChecked && enabled);
    return [
        CheckboxHOC({ disabled: !enabled, value: isChecked, onChange: () => setChecked(!isChecked) }),
        isChecked
    ]
}