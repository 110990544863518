import React, { useEffect, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer, QueryRenderer } from "react-relay"
import { AppUserOverview_appuser } from './__generated__/AppUserOverview_appuser.graphql'
import { logRoles } from '@testing-library/react';
import LogList from './LogList';
import PrivacyAuthorizationList from './PrivacyAuthorizationList';
import { LazyLog } from 'react-lazylog';
import LogViewer from './LogViewer';
import Split from 'react-split';
import SplitPane from 'react-split-pane';
import TripViewer from './TripViewer';
import { css } from 'emotion';
import { debounce } from 'debounce';
import IosDeviceViewer from './IosDeviceViewer';
import { DateContextControl, DateContextProvider } from './ContextualDate';
import UserControl from './auth/UserControl';
import { useHistory, useLocation, useParams } from 'react-router';
import { LogViewer_log } from './__generated__/LogViewer_log.graphql';
import { StringParam, useQueryParam } from 'use-query-params';
import AppUserInfo from './AppUserInfo';
import TripList from './TripList';

function Centered({ children }: { children: any }) {
    return (
        <div className={css`
            position: relative;
            height: 100%;
            width: 100%;
            `}>
            <div className={css`
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                `}>
                {children}
            </div>
        </div>
    );
}

function NoRoute() {
    return (
        <Centered>
            No route to show 😢
        </Centered>
    )
}

function NoLog() {
    return (
        <Centered>
            No logs 🌲
        </Centered>
    )
}

export type OverviewProps = {
    appuser: AppUserOverview_appuser,
}

/**
 * Full-page view of user's history. 
 * 
 * Handles passing Route and Log nodes between components, but doesn't interact
 * with URL query string or any other types of state
 */
export function AppUserOverview({ appuser }: { appuser: AppUserOverview_appuser }) {

    const [logNode, setLogNode] = useState<any>();
    const [tripNode, setTripNode] = useState<any>();
    const onPaneChange = () => window.dispatchEvent(new Event('resize'));

    const stuff = (
        <div className={css`overflow: auto; height: 100%;`}>
            <UserControl />
            <DateContextControl />
            <hr />
            <AppUserInfo appuser={appuser} />
            <IosDeviceViewer iosDevice={appuser.latestDevice?.edges[0]?.node} />
            <hr />
            <h4>Trips</h4>
            <TripList appuser={appuser} onSelectNode={setTripNode} />
            {/* <h4>Routes</h4> */}
            {/* <RouteList appuser={appuser} onSelectNode={setRouteNode} /> */}
            <h4>Authorized Logs</h4>
            <LogList
                appuser={appuser}
                onClickNode={setLogNode} />
            <h4>Privacy Authorizations</h4>
            <PrivacyAuthorizationList appuser={appuser} />
        </div>
    );
    return (
        <DateContextProvider>
            <SplitPane split="horizontal" minSize={"100%"} defaultSize={500} onDragFinished={onPaneChange}>
                <SplitPane split="vertical" minSize={"200"} defaultSize={500} onDragFinished={onPaneChange}>
                    {stuff}
                    {tripNode ? (
                        <TripViewer trip={tripNode} />
                    ) : (
                        <NoRoute />
                    )}
                </SplitPane>
                {logNode ? (
                    <LogViewer log={logNode} />
                ) : (
                    <NoLog />
                )}
            </SplitPane>
        </DateContextProvider>
    )
}

export default createFragmentContainer(AppUserOverview, {
    appuser: graphql`
fragment AppUserOverview_appuser on AppUserNode @argumentDefinitions(
        aroundTripUUID: { type: "String" }
    ) {
    id
    deleted
    intercomExternalUserId
    isInternalUser
    firstLogs: logs(first: 1) {
        edges {
            node {
                id
                ...LogViewer_log
            }
        }
    }
    firstTrips: apptrips(first: 1) {
        edges {
            node {
                id
                ...TripViewer_trip
            }
        }
    }
    ltruser {
        email
    }
    ...LogList_appuser
    ...PrivacyAuthorizationList_appuser
    ...TripList_appuser @arguments(first: 5, aroundTripUUID: $aroundTripUUID)
    # ...RouteList_appuser @arguments(first: 5, aroundRouteUUID: $aroundRouteUUID)
    latestDevice: iosDevices(first: 1) {
        edges {
            node {
                id
                ...IosDeviceViewer_iosDevice
            }
        }
    }
}
`
})