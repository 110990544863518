import { css } from 'emotion';
import React, { useState } from 'react';
import { AppUserOverview_appuser } from './__generated__/AppUserOverview_appuser.graphql';

function ShowButton({ startOpen, label, children }: { startOpen: boolean, label: string, children: React.ReactNode }) {
    const [show, setShow] = useState<boolean>(startOpen)
    const hiddenClassName = css`display: none;`
    return (
        <>
            <button className={show ? hiddenClassName : ''} onClick={() => setShow(!show)}>{label}</button>
            <span className={show ? '' : hiddenClassName}>{children}</span>
        </>

    )
}

export default function AppUserInfo({ appuser }: { appuser: AppUserOverview_appuser }) {
    const { isInternalUser, ltruser } = appuser;
    if (ltruser) {
        return (
            <div>User logged-in as:
                <ShowButton startOpen={isInternalUser} label={'<external user>'}>
                    <em>&nbsp;{ltruser.email}</em>
                </ShowButton>
            </div>
        )
    }
    else {
        return (<div>Anonymous user</div>)
    }
}