/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TripEndNotificationButtonMutationVariables = {
    input: string;
};
export type TripEndNotificationButtonMutationResponse = {
    readonly sendTripEndNotification: {
        readonly count: number;
        readonly message: string | null;
    } | null;
};
export type TripEndNotificationButtonMutation = {
    readonly response: TripEndNotificationButtonMutationResponse;
    readonly variables: TripEndNotificationButtonMutationVariables;
};



/*
mutation TripEndNotificationButtonMutation(
  $input: ID!
) {
  sendTripEndNotification(tripId: $input) {
    count
    message
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tripId",
        "variableName": "input"
      }
    ],
    "concreteType": "SendTripEndNotification",
    "kind": "LinkedField",
    "name": "sendTripEndNotification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TripEndNotificationButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TripEndNotificationButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cb9cef58c51b72fdb8fd9cb8a1a71007",
    "id": null,
    "metadata": {},
    "name": "TripEndNotificationButtonMutation",
    "operationKind": "mutation",
    "text": "mutation TripEndNotificationButtonMutation(\n  $input: ID!\n) {\n  sendTripEndNotification(tripId: $input) {\n    count\n    message\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c8cccebafa0193cac0b2b69d0b4c53ab';
export default node;
